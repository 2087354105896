import React, { useEffect, useRef } from "react";
import atoms from "../../../atoms";
import { numberWithCommas } from "../../../../constants/utils";
import constant from "../../../../constants/constant";
import moment from "moment";
import { Tooltip } from "react-tooltip";
import { InventoryBarcodeProps, MoveOutBarcodeData } from "./interface";
import { JsxElement } from "typescript";
import molecules from "../../../molecules";

const { Search, Table, Select, Pagination, CustomImages } = atoms;
const { ContactLensContainer } = molecules;

const Index = ({
  moverOutBarcode,
  setLimitPerPage,
  setCurrentPage,
  currentPage,
  limit,
  storeData,
  handleChange,
  setSearch,
  isContactLensProduct,
  setIsContactLensProduct
}: InventoryBarcodeProps) => {
  const inputRef: any = useRef()
  const columns = [
    {
      name: "Barcode",
      selector: (row: MoveOutBarcodeData) => `${row?.bar_code}`,
      sortable: true,
    },
    {
      name: "Date",
      selector: (row: MoveOutBarcodeData) =>
        row?.createdAt ? moment(row?.createdAt).format("DD/MM/YYYY") : "-",
    },
    {
      name: "Brand",
      selector: (row: MoveOutBarcodeData) => row?.product?.brand?.brand_name,
    },
    {
      name: "Store Name",
      selector: (row: MoveOutBarcodeData) => row?.store?.store_name || "",
    },
    {
      name: "Cover Photo",
      cell: (row: MoveOutBarcodeData) => (
        <CustomImages
          src={row?.product?.images[0] && row?.product?.images[0]}
        />
      ),
      center: true,
    },
    {
      name: "Product Type",
      selector: (row: MoveOutBarcodeData) =>
        row?.product?.product_type?.name || "",
    },
    {
      name: "Product ID",
      selector: (row: MoveOutBarcodeData) =>
        `${row?.product?.product_id_display || ""}`,
    },
    {
      name: "Model No",
      selector: (row: MoveOutBarcodeData) =>
        `${row?.product?.modal_number || ""}`,
    },
    {
      name: "Brand Color",
      selector: (row: MoveOutBarcodeData) => row?.color || "",
    },
    {
      name: "Glass Size/Power",
      selector: (row: MoveOutBarcodeData) => row?.product?.glass_size || "",
    },
    {
      name: "SRP (₹)",
      selector: (row: MoveOutBarcodeData) =>
        `₹${numberWithCommas(Number(row?.product?.product_srp || 0))}`,
    },
    {
      name: "Status",
      selector: (row: { isActive: JsxElement }) => row.isActive,
    },
  ];

  const coloredDivs = (color: any, index: number) => {
    if (color?.product?.specx_colors?.length === 2) {
      // Handle the case where there are two colors
      const [color1, color2] = color?.product?.specx_colors;

      return (
        <div
          key={`color${index}`}
          className="diagonal-color-div cursor-pointer"
          data-tooltip-id={`color${index}`}
          data-tooltip-content={color?.brand_color_name}
        >
          <div
            className="diagonal-color-div cursor-pointer rounded-circle"
            style={{ backgroundColor: color1?.color_code }}
          ></div>
          <Tooltip id={`color${index}`} />
        </div>
      );
    } else if (color?.product?.specx_colors?.length === 1) {
      // Handle the case where there is only one color
      const singleColor = color?.product?.specx_colors;
      return (
        <div
          key={`color${index}`}
          style={{ backgroundColor: singleColor[0].color_code }}
          className="color-div cursor-pointer"
          data-tooltip-id={`color${index}`}
          data-tooltip-content={color.brand_color_name}
        >
          <Tooltip id={`color${index}`} />
        </div>
      );
    } else {
      return null; // Handle other cases or add error handling as needed
    }
  };
  const handleLensTypeChange = (value: boolean) => {
    setCurrentPage(1);
    setSearch('')
    setIsContactLensProduct(value)

  }
  useEffect(() => {
    if (inputRef?.current) {
      inputRef.current.value = "";
    }
  }, [isContactLensProduct]);
  return (
    <>
      <div className="d-flex mb-2">
        <ContactLensContainer
          value={isContactLensProduct}
          onStatusChange={(value: boolean) => { handleLensTypeChange(value) }}
        />
      </div>
      <div className="d-flex align-items-center gap-3">
        <div className="">
          <Search
            refs={inputRef}
            searchCallBack={(e: string) => {
              setCurrentPage(1)
              setSearch(e);
            }}
            className="w-100"
          />
        </div>
        <div className="">
          <div className="" style={{ color: "#000000", minWidth: "230px" }}>
            <Select
              className={"w-100 select-min-height"}
              options={storeData}
              onChange={(e: any) => { setCurrentPage(1); handleChange(e) }}
              placeholder={"Select Store"}
              isMulti={true}
            />
          </div>
        </div>
        {/* <div className="">
          <ContactLensContainer value={true} onStatusChange={(value:boolean)=>console.log("-->")} />
        </div> */}
      </div>
      {moverOutBarcode?.data?.length > 0 && (
        <div>
          <Table
            columns={columns}
            data={moverOutBarcode?.data?.map((item, index: number) => {
              return {
                ...item,
                color: <> {coloredDivs(item, index)}</>,
                isActive: item.is_active ? (
                  <div className="background-light-green border-product-radius">
                    <p className="color-green m-1 p-1">
                      {constant.STATUS.ACTIVE}
                    </p>
                  </div>
                ) : (
                  <div className="background-light-red border-product-radius">
                    <p className="color-red m-1 p-1">
                      {constant.STATUS.DEACTIVE}
                    </p>
                  </div>
                ),
              };
            })}
          />
        </div>
      )}

      {moverOutBarcode?.data?.length > 0 && (
        <Pagination
          totalCount={moverOutBarcode?.count}
          currentPage={currentPage}
          totalPages={moverOutBarcode?.totalPages}
          onChangePage={(page: any) => setCurrentPage(page)}
          setLimit={(e: number) => { setCurrentPage(1); setLimitPerPage(e) }}
          limit={limit}
        />
      )}
    </>
  );
};

export default Index;
