import React, { useState } from 'react'
import atoms from '../../../atoms'
import service from '../../../../api/services';
import { useNavigate } from 'react-router-dom'
import PlaceHolderImage from '../../../../assets/Images/placeholderimg.png'
import { findConstantLabel } from '../../../../constants/utils';
import constant from '../../../../constants/constant';
import molecules from '../../../molecules';
import { useToasts } from 'react-toast-notifications';
import { RiDeleteBin6Line } from "react-icons/ri";
import moment from 'moment';

const { CreateModal } = molecules
const { Search, Button, Table, Pagination, Select, Input, CustomImages } = atoms

const Index = ({ setSearch, processListing, setLimitPerPage, setCurrentPage, currentPage, limit, storeList, storeListing, barcodeList, handleCancelled }: any) => {

    const [createModal, setCreateModal] = useState<boolean>(false)
    const [createCancelModal, setCreateCancelModal] = useState<boolean>(false)
    const [cancelReason, setCancelReason] = useState<any>()
    const [deliverForm, setDeliverForm] = useState<any>({
        store_id: '',
        barcode_id: '',
        delivery_note_num: 'D-',
        pickup_note_num: 'P-',
        ownership: null,
    })
    const [listId, setListId] = useState('')

    const navigate = useNavigate()
    const { addToast } = useToasts();

    const columns = [
        { name: 'Product ID', selector: (row: any) => row.product.product_id_display, minWidth: "150px" },
        { name: 'Movement ID', selector: (row: any) => row.procurement.procurement_display_id, minWidth: "120px" },
        {
            name: 'Product Type / Frame /Glass Size/Power', selector: (row: any) =>
                `${row?.product?.product_type?.name}
         ${findConstantLabel(constant?.PRODUCT_RIM_TYPE, row?.product?.rim_type)}
         ${row?.product?.glass_size}`, minWidth: "120px"
        },
        { name: 'Date Time', selector: (row: any) => `${row?.createdAt ? moment(row?.updatedAt).format('DD/MM/YYYY , H:mm:ss') : "--"}`, sortable: true, minWidth: "120px" },
        { name: 'Cover Photo', cell: (row: any) => <CustomImages src={row?.product?.images?.length > 0 && row?.product?.images[0]} />, center: true, minWidth: "120px" },
        { name: 'Requested Store Name', selector: (row: any) => row.requested_store.store_location || '-', minWidth: "200px" },
        { name: 'Pickup Store', selector: (row: any) => row?.pickup_store?.store_name || '-', minWidth: "120px" },
        // { name: 'Delivery Boy', selector: (row: any) => row?.manager_name || '-', },
        { name: 'Order ID/Warranty Claim ID', selector: (row: any) => row?.order?.order_id_display ? row?.order?.order_id_display : row?.warranty_claimed_product?.claim_id, minWidth: "140px" },
        { name: 'Status', selector: (row: any) => row?.status || '-', minWidth: "150px" },
        { name: 'Action', selector: (row: any) => row.action, minWidth: "250px" },
    ]

    const handleSubmit = async () => {
        let payload = {
            store_id: deliverForm?.store_id,
            inventory_item_id: deliverForm?.barcode_id?.value,
            delivery_note_num: deliverForm?.delivery_note_num,
            pickup_note_num: deliverForm?.pickup_note_num,
            ownership: deliverForm?.ownership

        }
        try {
            const response = await service.movement.addInProcess(listId, payload)
            if (response.status === 200) {
                addToast(response?.data?.message, {
                    appearance: 'success',
                    autoDismiss: true,
                    autoDismissTimeout: 3000,
                });
                navigate("/movement/delivered");
            }
        } catch (error: any) {
            console.log(error)
            addToast(error?.response?.data?.message, {
                appearance: 'error',
                autoDismiss: true,
                autoDismissTimeout: 3000,
            });
        }
    }
    const _handleDeliverModal = async (id: string) => {
        storeListing(id)
        setListId(id)
        setCreateModal(true)
    }
    const _handleClose = () => {
        setDeliverForm({
            ...deliverForm,
            store_id: '',
            barcode_id: '',
            delivery_note_num: 'D-',
            pickup_note_num: 'P-',
            ownership: null
        })
        setCreateModal(false)
    }

    const handleChange = (name: string, value: any) => {
        setDeliverForm({ ...deliverForm, [name]: value })
    }

    const handleCancelResponse = async () => {
        await handleCancelled(listId, cancelReason?.label)
        setCreateCancelModal(!createCancelModal)
    }

    const buttonValidation = (validValue: any) => {
        const requiredFields = ['store_id', 'barcode_id', 'ownership'];
        for (let field of requiredFields) {
            if (!validValue?.[field]) {
                return true;
            }
        }
        const notes = ['delivery_note_num', 'pickup_note_num'];
        for (let note of notes) {
            if (!validValue?.[note]?.slice(2)) {
                return true;
            }
        }
        return false;
    }


    return (
        <div>
            <div className="row align-items-center">
                <div className="col-6">
                    <Search searchCallBack={(e: string) => { setSearch(e) }} />
                </div>
            </div>
            <div>
                <Table

                    AdditionalClassName="add-invertory-table"
                    className="inventory-product-border"
                    columns={columns}
                    data={processListing?.data?.map((item: any) => {
                        return ({
                            ...item,
                            status: item.status === constant._STATUS.PICKUP ? (
                                <div className="background-light-yellow border-product-radius"><p className="color-yellow m-1 p-1">{'Picked Up'}</p></div>) : item.status === constant._STATUS.DELIVER ? (<div className="background-light-red border-product-radius"><p className="color-red m-1 p-1">{"To be Delivered"}</p></div>) : '',
                            action:
                                <div className='d-flex gap-3' style={{ overflow: 'unset', textOverflow: 'unset' }}>
                                    <Button className="table-edit-button fs-14 fw-400"
                                        onClick={() => {
                                            _handleDeliverModal(item.id)
                                        }}
                                    >
                                        <div className='d-flex align-items-center'>
                                            <span className='ms-2 mb-0'>Deliver</span>
                                        </div>
                                    </Button>
                                    <Button className="table-delete-button fs-14 fw-400"
                                        onClick={() => { setCreateCancelModal(!createCancelModal), setListId(item?.id) }}>
                                        <div className='d-flex align-items-center'>
                                            <RiDeleteBin6Line />
                                            <span className='ms-2 mb-0'>Cancel</span>
                                        </div>
                                    </Button>
                                </div>

                        })
                    })}
                />
            </div>
            <div>
                <Pagination
                    totalCount={processListing?.count}
                    currentPage={currentPage}
                    totalPages={processListing?.totalPages}
                    onChangePage={(page: any) => setCurrentPage(page)}
                    setLimit={(e: number) => setLimitPerPage(e)}
                    limit={limit}
                />
            </div>
            {createModal && <CreateModal
                show={createModal}
                header={`Store`}
                buttonDisable={buttonValidation(deliverForm)}
                handleSubmit={() => handleSubmit()}
                submitBtnText={`Deliver`}
                onHide={() => _handleClose()}
            >
                <div>
                    <form>
                        <div className="row">
                            <div className="col-12 mt-1">
                                <Select className={"w-100"} options={storeList}
                                    onChange={(e: any) => handleChange("store_id", e.value)}
                                    placeholder={"Select"}
                                    label={"Delivered To"} />
                            </div>
                            <div className="col-12 mt-3">
                                <Select className={"w-100"} options={barcodeList}
                                    onChange={(e: any) => handleChange("barcode_id", e)}
                                    placeholder={"Select"}
                                    label={"Select Barcode"} />
                                {deliverForm?.barcode_id && <h6 className='mt-1 color-gray'>Currently in {deliverForm?.barcode_id?.store_ownership ? 'Store' : 'Specxweb'} Ownership</h6>}
                            </div>
                            <div className="col-12 mt-2">
                                <Input label={"Delivery Note No."}
                                    type={"text"}
                                    placeholder={"Enter Delivery Note No."}
                                    value={deliverForm?.delivery_note_num}
                                    onChange={(e: any) => {
                                        handleChange("delivery_note_num", e.target.value)
                                    }}
                                />
                            </div>
                            <div className="col-12 mt-3">
                                <Input
                                    label={"Pickup Note No."}
                                    type={"text"}
                                    placeholder={"Enter Pickup Note No."}
                                    value={deliverForm.pickup_note_num}
                                    onChange={(e: any) => {
                                        handleChange("pickup_note_num", e.target.value)
                                    }}
                                />
                            </div>
                            <div className="col-12 mt-3">
                                <Select className={"w-100"} options={constant.STORE_OWNERSHIP}
                                    onChange={(e: any) => handleChange("ownership", e.value)}
                                    placeholder={"Select Store Ownership"}
                                    label={"Transfer in Ownership of"} />
                            </div>
                        </div>
                    </form>
                </div>
            </CreateModal>}

            {createCancelModal && <CreateModal
                show={createCancelModal}
                header={`Cancel Pickup`}
                handleSubmit={() => handleCancelResponse()}
                submitBtnText={`Confirm`}
                onHide={() => { setCreateCancelModal(!createCancelModal); setCancelReason("") }}
            >
                <div>
                    <form>
                        <div className="row">
                            <div className="col-12">
                                <Select label={"Reason"}
                                    className={"w-100"}
                                    options={constant.MovementCancelReason}
                                    onChange={(e: any) => setCancelReason(e)}
                                />
                            </div>
                        </div>
                    </form>
                </div>
            </CreateModal>}
        </div>
    )
}

export default Index