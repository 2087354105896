import React from "react";
import "./index.scss"
import "bootstrap/dist/css/bootstrap.min.css";
import { DatePickerProps } from "./interface";

const Index = ({label,placeholder,selectedYear, setSelectedYear}:DatePickerProps) => {
    const currentYear = new Date()?.getFullYear();
    const years = Array?.from({ length: currentYear - 1800 + 1 }, (_, i) => 1800 + i);

    const handleYearChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedYear(parseInt(event?.target?.value, 10));
    };

    return (
        <div className="container">
           {label && <label className="inputs-label mb-2 fs-16 fw-600 secondary-color">
                {label}
            </label>}
            <select
                id="year-picker"
                className={`year_multi_select inputs form-control ${!selectedYear ? "fs-16 fw-400 secondary-color" : ""}`}
                value={selectedYear || ""}
                onChange={handleYearChange}
            >
                <option value="" disabled>
                   {placeholder ? "Select Year" : placeholder}
                </option>
                {years?.map((year) => (
                    <option key={year} value={year}>
                        {year}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default Index;
