import React, { useContext } from 'react'
import "./Sidebar.scss"
import Logo from '../../../assets/Images/logo1.png'
import Menu from '../../../assets/Images/menu.svg'
import Atoms from '../../atoms'
import { NavLink, useLocation } from 'react-router-dom'
import { RxDashboard } from "react-icons/rx";
import { AiOutlineBarChart, AiOutlineBarcode, AiOutlineBgColors, AiOutlineShop } from "react-icons/ai";
import { VscTypeHierarchySub } from "react-icons/vsc";
import { TbBrandBeats } from "react-icons/tb";
import { SiMaterialdesign } from "react-icons/si";
import { PiShapesBold } from "react-icons/pi";
import { TiArrowRepeat } from "react-icons/ti";
import { BsArrowLeftRight } from 'react-icons/bs'
import { checkPermissionsInArray, isActiveQuery } from '../../../constants/utils'
import { AllPermissions } from '../../../constants/permissions'
import { HeaderHeading, BadgesCount } from '../../../Context'
import LoginImage from '../../../assets/Images/specx_logo.jpg'
import { useNavigate } from 'react-router-dom'
import constant from '../../../constants/constant'
import { TbBrandProducthunt } from "react-icons/tb";
import { CgWebsite } from "react-icons/cg";
import { TbReport } from "react-icons/tb";
import { MdOutlineAccountBalanceWallet } from "react-icons/md";
import { IoSettingsOutline } from "react-icons/io5";
import { LiaStoreAltSolid } from "react-icons/lia";
import { PiTruck } from "react-icons/pi";

const { Image, Badge } = Atoms
const SideBar = () => {
  const navigate = useNavigate()
  const location = useLocation();
  const { sidebar }: any = useContext(HeaderHeading);
  const [notificationCount, setNotificationCount] = useContext<any>(BadgesCount);

  // const isActiveQuery = (queryKey: any, queryValue: any) => {
  //   const urlParams = new URLSearchParams(location.search);
  //   return urlParams?.get(queryKey) == queryValue;
  // };

  const renderNavLink = (permission: any, to: any, icon: any, label: any) => {
    return (
      checkPermissionsInArray(permission) && (
        <NavLink
          className="s-sidebar__nav-link d-flex gap-3 align-items-center ps-5 text-decoration-none sidebar-menu-fs fs-14 black-color"
          to={to}
        >
          {icon}
          <li className="sidebar-list-styled">{label}</li>
        </NavLink>
      )
    );
  };

  return (
    <div>
      <div className="s-layout nav-scroll">
        <div className="s-layout__sidebar ">
          <a className="s-sidebar__trigger" href="#0">
            <Image src={Menu} />
          </a>
          <nav className="s-sidebar__nav">
            <ul>
              <div className="w-100 text-center" >
                <Image src={LoginImage} className='img-fluid logo' onClick={() => navigate('/dashboard')} style={{ cursor: "pointer" }} />
              </div>
              <div className="mt-25"></div>
              {/* {checkPermissionsInArray(AllPermissions.Dashboard_Tab) && <li>
                <NavLink className="s-sidebar__nav-link d-flex gap-3 align-items-center ps-4 text-decoration-none sidebar-menu-fs fs-14 black-color" to="/dashboard">
                  <span><RxDashboard className='fs-18' /></span>Dashboard
                </NavLink>
              </li>} */}

              {checkPermissionsInArray(AllPermissions.Content_Tab) &&
                <li>
                  <div className="accordion" id="contentAccordion">
                    <div className="accordion-item border-0">
                      <h2 className="accordion-header " id="contentHeading">
                        <button
                          className="collapsed accordion-button bg-body shadow-none s-sidebar__nav-link d-flex gap-3 align-items-center ps-4 text-decoration-none sidebar-menu-fs fs-14 black-color rounded-0"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#contentCollapse"
                          aria-expanded="false"
                          aria-controls="contentCollapse"
                        >
                          <AiOutlineBarChart className='fs-18' />
                          Content
                        </button>
                      </h2>
                      <div
                        id="contentCollapse"
                        className="accordion-collapse collapse"
                        aria-labelledby="contentHeading"
                        data-bs-parent="#contentAccordion"
                      >
                        <div className="accordion-body p-0">
                          <ul className="list-unstyled">
                            {/* {checkPermissionsInArray(AllPermissions.Brand_Tab) && <NavLink className="s-sidebar__nav-link d-flex gap-3 align-items-center ps-5 text-decoration-none sidebar-menu-fs fs-14 black-color"
                              to="/product/brand">
                              <TbBrandBeats className='fs-20' />
                              <li className='sidebar-list-styled'>Brands</li>
                            </NavLink>} */}
                            {checkPermissionsInArray(AllPermissions.Product_Type_Tab) && <NavLink className="s-sidebar__nav-link d-flex gap-3 align-items-center ps-5 text-decoration-none sidebar-menu-fs fs-14 black-color"
                              to="/product/type">
                              <VscTypeHierarchySub className='fs-18' />
                              <li className='sidebar-list-styled'>Product Type</li>
                            </NavLink>}
                            {checkPermissionsInArray(AllPermissions.Product_Material_Tab) && <NavLink className="s-sidebar__nav-link d-flex gap-3 align-items-center ps-5 text-decoration-none sidebar-menu-fs fs-14 black-color"
                              to="/product/material">
                              <SiMaterialdesign className='fs-18 fw-800' />
                              <li className='sidebar-list-styled'>Product Material</li>
                            </NavLink>}
                            {checkPermissionsInArray(AllPermissions.Sw_color_Tab) && <NavLink className="s-sidebar__nav-link d-flex gap-3 align-items-center ps-5 text-decoration-none sidebar-menu-fs fs-14 black-color"
                              to="/product/color">
                              <AiOutlineBgColors className='fs-20' />
                              <li className='sidebar-list-styled'>Specx Color Code</li>
                            </NavLink>}
                            {checkPermissionsInArray(AllPermissions.Shape_Tab) && <NavLink className="s-sidebar__nav-link d-flex gap-3 align-items-center ps-5 text-decoration-none sidebar-menu-fs fs-14 black-color"
                              to="/product/shape">
                              <PiShapesBold className='fs-18' />
                              <li className='sidebar-list-styled'>Shapes</li>
                            </NavLink>}

                            {/* TODO create permission for size ,Bridge Size and Temple Size */}
                            {checkPermissionsInArray(AllPermissions.Shape_Tab) && <NavLink className="s-sidebar__nav-link d-flex gap-3 align-items-center ps-5 text-decoration-none sidebar-menu-fs fs-14 black-color"
                              to="/size/list">
                              <PiShapesBold className='fs-18' />
                              <li className='sidebar-list-styled'>Size</li>
                            </NavLink>}
                             {checkPermissionsInArray(AllPermissions.Shape_Tab) && <NavLink className="s-sidebar__nav-link d-flex gap-3 align-items-center ps-5 text-decoration-none sidebar-menu-fs fs-14 black-color"
                              to="/power/list">
                              <PiShapesBold className='fs-18' />
                              <li className='sidebar-list-styled'>Power</li>
                            </NavLink>}
                            {checkPermissionsInArray(AllPermissions.Shape_Tab) && <NavLink className="s-sidebar__nav-link d-flex gap-3 align-items-center ps-5 text-decoration-none sidebar-menu-fs fs-14 black-color"
                              to="/bridge-size/list">
                              <PiShapesBold className='fs-18' />
                              <li className='sidebar-list-styled'>Bridge Size</li>
                            </NavLink>}
                            {checkPermissionsInArray(AllPermissions.Shape_Tab) && <NavLink className="s-sidebar__nav-link d-flex gap-3 align-items-center ps-5 text-decoration-none sidebar-menu-fs fs-14 black-color"
                              to="/temple-size/list">
                              <PiShapesBold className='fs-18' />
                              <li className='sidebar-list-styled'>Temple Size</li>
                            </NavLink>}
                            {checkPermissionsInArray(AllPermissions.Supplier_Tab) && <NavLink className="s-sidebar__nav-link d-flex gap-3 align-items-center ps-5 text-decoration-none sidebar-menu-fs fs-14 black-color"
                              to="/supplier">
                              <PiTruck className='fs-18' />
                              <li className='sidebar-list-styled'>Supplier</li>
                            </NavLink>}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>}


              <li>
                <div className="accordion" id="unrecognizeAccordion">
                  <div className="accordion-item border-0">
                    <h2 className="accordion-header" id="unrecognizeHeading">
                      <button
                        className="collapsed accordion-button bg-body shadow-none s-sidebar__nav-link d-flex gap-3 align-items-center ps-4 text-decoration-none sidebar-menu-fs fs-14 black-color rounded-0"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#unrecognizeCollapse"
                        aria-expanded="false"
                        aria-controls="unrecognizeCollapse"
                      >
                        <AiOutlineBarChart className="fs-18" />
                        Unrecognize
                      </button>
                    </h2>
                    <div
                      id="unrecognizeCollapse"
                      className="accordion-collapse collapse"
                      aria-labelledby="unrecognizeHeading"
                      data-bs-parent="#unrecognizeAccordion"
                    >
                      <div className="accordion-body p-0">
                        <ul className="list-unstyled">
                          {checkPermissionsInArray(AllPermissions.Brand_Tab) && <NavLink
                            className={`s-sidebar__nav-link d-flex gap-3 align-items-center ps-5 text-decoration-none sidebar-menu-fs fs-14 black-color ${isActiveQuery('brand-type', constant.BRAND_CATEGORY[1].value) ? 'active' : 'nav-deactive'}`}
                            to={`/product/brand?brand-type=${constant.BRAND_CATEGORY[1].value}`}>
                            <TbBrandBeats className='fs-20' />
                            <li className='sidebar-list-styled'>Brands</li>
                          </NavLink>}
                          {checkPermissionsInArray(AllPermissions.Product_Tab) && <NavLink
                            className={`s-sidebar__nav-link d-flex gap-3 align-items-center ps-5 text-decoration-none sidebar-menu-fs fs-14 black-color ${isActiveQuery('approved-type', constant.BRAND_CATEGORY[1].value) ? 'active' : 'nav-deactive'}`}
                            to={`/recognize-products?approved-type=${constant.BRAND_CATEGORY[1].value}`}>
                            <TbBrandProducthunt className='fs-20' />
                            <li className='sidebar-list-styled'>Product</li>
                          </NavLink>}
                          {/* {checkPermissionsInArray(AllPermissions.Product_Tab) && <li>
                            <div className="accordion" id="accordionExample">
                              <div className="accordion-item border-0">
                                <h2 className="accordion-header " id="productId">
                                  <button
                                    className="collapsed accordion-button bg-body shadow-none s-sidebar__nav-link d-flex gap-3 align-items-center ps-5 text-decoration-none sidebar-menu-fs fs-14 black-color rounded-0"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseProduct"
                                    aria-expanded="false"
                                    aria-controls="collapseProduct"
                                  >
                                    <AiOutlineBarChart className='fs-18' />
                                    Product
                                  </button>
                                </h2>
                                <div
                                  id="collapseProduct"
                                  className="accordion-collapse collapse"
                                  aria-labelledby="productId"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div className="accordion-body p-0">
                                    <ul className="list-unstyled">
                                      {checkPermissionsInArray(AllPermissions.Product_Rework_Tab) && <NavLink
                                        className={`s-sidebar__nav-link d-flex gap-3 align-items-center ps-6 text-decoration-none sidebar-menu-fs fs-14 black-color ${isActiveQuery('rework-type', constant.BRAND_CATEGORY[1].value) ? 'active' : 'nav-deactive'}`}
                                        to={`/products/rework?rework-type=${constant.BRAND_CATEGORY[1].value}`}>
                                        <BsArrowLeftRight className='fs-20' />
                                        <li className='sidebar-list-styled'>Rework</li>
                                      </NavLink>}
                                      {checkPermissionsInArray(AllPermissions.Product_Pending_Tab) && <NavLink
                                        className={`s-sidebar__nav-link d-flex gap-3 align-items-center ps-6 text-decoration-none sidebar-menu-fs fs-14 black-color ${isActiveQuery('pending-type', constant.BRAND_CATEGORY[1].value) ? 'active' : 'nav-deactive'}`}
                                        to={`/products/pending?pending-type=${constant.BRAND_CATEGORY[1].value}`}>
                                        <BsArrowLeftRight className='fs-20' />
                                        <li className='sidebar-list-styled'>Pending</li>
                                      </NavLink>}
                                      {checkPermissionsInArray(AllPermissions.Product_Approved_Tab) && <NavLink
                                        className={`s-sidebar__nav-link d-flex gap-3 align-items-center ps-6 text-decoration-none sidebar-menu-fs fs-14 black-color ${isActiveQuery('approved-type', constant.BRAND_CATEGORY[1].value) ? 'active' : 'nav-deactive'}`}
                                        to={`/products/approved?approved-type=${constant.BRAND_CATEGORY[1].value}`}>
                                        <BsArrowLeftRight className='fs-20' />
                                        <li className='sidebar-list-styled'>Approved</li>
                                      </NavLink>}
                                      {checkPermissionsInArray(AllPermissions.Product_Bulk_Edit_Tab) && <NavLink
                                        className={`s-sidebar__nav-link d-flex gap-3 align-items-center ps-6 text-decoration-none sidebar-menu-fs fs-14 black-color ${isActiveQuery('bulk-edit-type', constant.BRAND_CATEGORY[1].value) ? 'active' : 'nav-deactive'}`}
                                        to={`/products/bulk-edit?bulk-edit-type=${constant.BRAND_CATEGORY[1].value}`}>
                                        <BsArrowLeftRight className='fs-20' />
                                        <li className='sidebar-list-styled'>Bulk Edit</li>
                                      </NavLink>}
                                      {checkPermissionsInArray(AllPermissions.Product_Bulk_Upload_Tab) && <NavLink
                                        className={`s-sidebar__nav-link d-flex gap-3 align-items-center ps-6 text-decoration-none sidebar-menu-fs fs-14 black-color ${isActiveQuery('bulk-upload-type', constant.BRAND_CATEGORY[1].value) ? 'active' : 'nav-deactive'}`}
                                        to={`/products/bulk-upload?bulk-upload-type=${constant.BRAND_CATEGORY[1].value}`}>
                                        <BsArrowLeftRight className='fs-20' />
                                        <li className='sidebar-list-styled'>Bulk Upload</li>
                                      </NavLink>}

                               
                                      {checkPermissionsInArray(AllPermissions.Product_Bulk_Upload_Tab) && <NavLink
                                        className={`s-sidebar__nav-link d-flex gap-3 align-items-center ps-6 text-decoration-none sidebar-menu-fs fs-14 black-color ${isActiveQuery('bulk-filter-type', constant.BRAND_CATEGORY[1].value) ? 'active' : 'nav-deactive'}`}
                                        //permission is pending
                                        to={`/products/bulk-filter?bulk-filter-type=${constant.BRAND_CATEGORY[1].value}`}>
                                        <BsArrowLeftRight className='fs-20' />
                                        <li className='sidebar-list-styled'>Bulk Filter</li>
                                      </NavLink>}
                                      {checkPermissionsInArray(AllPermissions.Product_Bulk_Upload_Tab) && <NavLink
                                        className={`s-sidebar__nav-link d-flex gap-3 align-items-center ps-6 text-decoration-none sidebar-menu-fs fs-14 black-color ${isActiveQuery('bulk-approve-type', constant.BRAND_CATEGORY[1].value) ? 'active' : 'nav-deactive'}`}
                                        //permission is pending
                                        to={`/products/bulk-approve?bulk-approve-type=${constant.BRAND_CATEGORY[1].value}`}>
                                        <BsArrowLeftRight className='fs-20' />
                                        <li className='sidebar-list-styled'>Bulk Approve</li>
                                      </NavLink>}
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>} */}

                          {checkPermissionsInArray(AllPermissions.Inventory_Tab) && <li className="">
                            <div className="accordion" id="unrecognizeAccordionExample">
                              <div className="accordion-item border-0">
                                <h2 className="accordion-header " id="unrecognizeHeadingTwo">
                                  <button
                                    className="collapsed accordion-button bg-body shadow-none s-sidebar__nav-link d-flex gap-2 align-items-center ps-5 text-decoration-none sidebar-menu-fs fs-14 black-color rounded-0"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#unrecognizeCollapseTwo"
                                    aria-expanded="false"
                                    aria-controls="collapseTwo"
                                  >
                                    <AiOutlineBarChart className="fs-18" />
                                    Inventory
                                    <Badge count={(notificationCount?.inventory_pending_count + notificationCount?.barcode_pending_count)} />
                                  </button>
                                </h2>
                                <div
                                  id="unrecognizeCollapseTwo"
                                  className="accordion-collapse collapse"
                                  aria-labelledby="unrecognizeHeadingTwo"
                                  data-bs-parent="#unrecognizeAccordionExample"
                                >
                                  <div className="accordion-body p-0">
                                    <ul className="list-unstyled">

                                      {
                                        checkPermissionsInArray(AllPermissions.Inventory_Pending_Tab) &&
                                        <NavLink
                                          className={`s-sidebar__nav-link d-flex gap-3 align-items-center ps-6 text-decoration-none sidebar-menu-fs fs-14 black-color 
                                        ${isActiveQuery('pending-list-type', constant.BRAND_CATEGORY[1].value) ? 'active' : 'nav-deactive'}`}
                                          to={`/inventory/pending/list?pending-list-type=${constant.BRAND_CATEGORY[1].value}`}>
                                          <BsArrowLeftRight className='fs-20' />
                                          <li className='sidebar-list-styled overflow-hidden text-break'>Pending/Rework</li>
                                          <Badge count={notificationCount?.inventory_pending_count} className='translate-50' />
                                        </NavLink>
                                      }

                                      {
                                        checkPermissionsInArray(AllPermissions.Inventory_Approved_Tab) &&
                                        <NavLink
                                          className={`s-sidebar__nav-link d-flex gap-3 align-items-center ps-6 text-decoration-none sidebar-menu-fs fs-14 black-color 
                                        ${isActiveQuery('approve-list-type', constant.BRAND_CATEGORY[1].value) ? 'active' : 'nav-deactive'}`}
                                          to={`/inventory/approve/list?approve-list-type=${constant.BRAND_CATEGORY[1].value}`}>
                                          <BsArrowLeftRight className='fs-20' />
                                          <li className='sidebar-list-styled'>Approved</li>
                                        </NavLink>}
                                      {
                                        checkPermissionsInArray(AllPermissions.Inventory_Search_Tab) &&
                                        <NavLink
                                          className={`s-sidebar__nav-link d-flex gap-3 align-items-center ps-6 text-decoration-none sidebar-menu-fs fs-14 black-color 
                                        ${isActiveQuery('search-type', constant.BRAND_CATEGORY[1].value) ? 'active' : 'nav-deactive'}`}
                                          to={`/inventory/search?search-type=${constant.BRAND_CATEGORY[1].value}`}>
                                          <BsArrowLeftRight className='fs-20' />
                                          <li className='sidebar-list-styled'>Search</li>
                                        </NavLink>}
                                      {
                                        checkPermissionsInArray(AllPermissions.Inventory_MinQty_Tab) &&
                                        <NavLink
                                          className={`s-sidebar__nav-link d-flex gap-3 align-items-center ps-6 text-decoration-none sidebar-menu-fs fs-14 black-color 
                                        ${isActiveQuery('quantity-type', constant.BRAND_CATEGORY[1].value) ? 'active' : 'nav-deactive'}`}
                                          to={`/inventory/quantity?quantity-type=${constant.BRAND_CATEGORY[1].value}`}>
                                          <BsArrowLeftRight className='fs-20' />
                                          <li className='sidebar-list-styled'>Visible Min Qty</li>
                                        </NavLink>}
                                      {
                                        checkPermissionsInArray(AllPermissions.Barcode_Pending) &&
                                        <NavLink
                                          className={`s-sidebar__nav-link d-flex gap-3 align-items-center ps-6 text-decoration-none sidebar-menu-fs fs-14 black-color 
                                        ${isActiveQuery('status-pending-type', constant.BRAND_CATEGORY[1].value) ? 'active' : 'nav-deactive'}`}
                                          to={`/inventory/barcode-status/pending?status-pending-type=${constant.BRAND_CATEGORY[1].value}`}>
                                          <BsArrowLeftRight className='fs-20' />
                                          <li className='sidebar-list-styled'>Pending Barcode</li>
                                          <Badge count={notificationCount?.barcode_pending_count} className='translate-50' />
                                        </NavLink>}
                                      {
                                        checkPermissionsInArray(AllPermissions.Barcode_Approved) &&
                                        <NavLink
                                          className={`s-sidebar__nav-link d-flex gap-3 align-items-center ps-6 text-decoration-none sidebar-menu-fs fs-14 black-color 
                                        ${isActiveQuery('status-approve-type', constant.BRAND_CATEGORY[1].value) ? 'active' : 'nav-deactive'}`}
                                          to={`/inventory/barcode-status/approved?status-approve-type=${constant.BRAND_CATEGORY[1].value}`}>
                                          <BsArrowLeftRight className='fs-20' />
                                          <li className='sidebar-list-styled'>Approve Barcode</li>
                                        </NavLink>}
                                      {/* TODO make permission of Price Updated Barcode */}
                                      {
                                        checkPermissionsInArray(AllPermissions.Barcode_Approved) &&
                                        <NavLink
                                          className={`s-sidebar__nav-link d-flex gap-3 align-items-center ps-6 text-decoration-none sidebar-menu-fs fs-14 black-color 
                                        ${isActiveQuery('status-price-updated-type', constant.BRAND_CATEGORY[1].value) ? 'active' : 'nav-deactive'}`}
                                          to={`/inventory/barcode-status/price-updated?status-price-updated-type=${constant.BRAND_CATEGORY[1].value}`}>
                                          <BsArrowLeftRight className='fs-20' />
                                          <li className='sidebar-list-styled'>Price Updated Barcode</li>
                                        </NavLink>
                                      }
                                      {/* TODO make permission for MoveOut Barcode */}
                                      {
                                        checkPermissionsInArray(AllPermissions.Barcode_Approved) &&
                                        <NavLink
                                          className={`s-sidebar__nav-link d-flex gap-3 align-items-center ps-6 text-decoration-none sidebar-menu-fs fs-14 black-color 
                                        ${isActiveQuery('status-moveout-type', constant.BRAND_CATEGORY[1].value) ? 'active' : 'nav-deactive'}`}
                                          to={`/inventory/barcode-status/move-out?status-moveout-type=${constant.BRAND_CATEGORY[1].value}`}>
                                          <BsArrowLeftRight className='fs-20' />
                                          <li className='sidebar-list-styled'>Moved Out</li>
                                        </NavLink>}
                                      {
                                        checkPermissionsInArray(AllPermissions.Inventory_Audit_Tab) &&
                                        <NavLink
                                          className={`s-sidebar__nav-link d-flex gap-3 align-items-center ps-6 text-decoration-none sidebar-menu-fs fs-14 black-color 
                                        ${isActiveQuery('audit-type', constant.BRAND_CATEGORY[1].value) ? 'active' : 'nav-deactive'}`}
                                          to={`/store/inventory-audit?audit-type=${constant.BRAND_CATEGORY[1].value}`}>
                                          <BsArrowLeftRight className='fs-20' />
                                          <li className='sidebar-list-styled'>
                                            Inventory Audit
                                          </li>
                                        </NavLink>}
                                      {/* TODO make permission for lost barcode and Lost barcode History */}
                                      {
                                        checkPermissionsInArray(AllPermissions.Inventory_Audit_Tab) &&
                                        <NavLink
                                          className={`s-sidebar__nav-link d-flex gap-3 align-items-center ps-6 text-decoration-none sidebar-menu-fs fs-14 black-color 
                                        ${isActiveQuery('lost-barcode-type', constant.BRAND_CATEGORY[1].value) ? 'active' : 'nav-deactive'}`}
                                          to={`/inventory/lost-barcode?lost-barcode-type=${constant.BRAND_CATEGORY[1].value}`}>
                                          <BsArrowLeftRight className='fs-20' />
                                          <li className='sidebar-list-styled'>
                                            Lost Barcode
                                          </li>
                                        </NavLink>}
                                      {
                                        checkPermissionsInArray(AllPermissions.Inventory_Audit_Tab) &&
                                        <NavLink
                                          className={`s-sidebar__nav-link d-flex gap-3 align-items-center ps-6 text-decoration-none sidebar-menu-fs fs-14 black-color 
                                        ${isActiveQuery('history-type', constant.BRAND_CATEGORY[1].value) ? 'active' : 'nav-deactive'}`}
                                          to={`/inventory/lost-barcode-history?history-type=${constant.BRAND_CATEGORY[1].value}`}>
                                          <BsArrowLeftRight className='fs-20' />
                                          <li className='sidebar-list-styled'>
                                            Lost Barcode History
                                          </li>
                                        </NavLink>}
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </li>


              <li>
                <div className="accordion" id="recognizeAccordion">
                  <div className="accordion-item border-0">
                    <h2 className="accordion-header" id="recognizeHeading">
                      <button
                        className="collapsed accordion-button bg-body shadow-none s-sidebar__nav-link d-flex gap-3 align-items-center ps-4 text-decoration-none sidebar-menu-fs fs-14 black-color rounded-0"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#recognizeCollapse"
                        aria-expanded="false"
                        aria-controls="recognizeCollapse"
                      >
                        <AiOutlineBarChart className="fs-18" />
                        Recognize
                      </button>
                    </h2>
                    <div
                      id="recognizeCollapse"
                      className="accordion-collapse collapse"
                      aria-labelledby="recognizeHeading"
                      data-bs-parent="#recognizeAccordion"
                    >
                      <div className="accordion-body p-0">
                        <ul className="list-unstyled">
                          {checkPermissionsInArray(AllPermissions.Brand_Tab) && <NavLink
                            className={`s-sidebar__nav-link d-flex gap-3 align-items-center ps-5 text-decoration-none sidebar-menu-fs fs-14 black-color ${isActiveQuery('brand-type', constant.BRAND_CATEGORY[0].value) ? 'active' : 'nav-deactive'}`}
                            to={`/product/brand?brand-type=${constant.BRAND_CATEGORY[0].value}`}>
                            <TbBrandBeats className='fs-20' />
                            <li className='sidebar-list-styled'>Brands</li>
                          </NavLink>}

                          {checkPermissionsInArray(AllPermissions.Product_Tab) && <li>
                            <div className="accordion" id="accordionExample">
                              <div className="accordion-item border-0">
                                <h2 className="accordion-header " id="recognizeProductId">
                                  <button
                                    className="collapsed accordion-button bg-body shadow-none s-sidebar__nav-link d-flex gap-3 align-items-center ps-5 text-decoration-none sidebar-menu-fs fs-14 black-color rounded-0"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseRecognizeP"
                                    aria-expanded="false"
                                    aria-controls="collapseRecognizeP"

                                  >
                                    <TbBrandProducthunt className='fs-18' />
                                    Product
                                  </button>
                                </h2>
                                <div
                                  id="collapseRecognizeP"
                                  className="accordion-collapse collapse"
                                  aria-labelledby="recognizeProductId"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div className="accordion-body p-0">
                                    <ul className="list-unstyled">
                                      {checkPermissionsInArray(AllPermissions.Product_Rework_Tab) && <NavLink
                                        className={`s-sidebar__nav-link d-flex gap-3 align-items-center ps-6 text-decoration-none sidebar-menu-fs fs-14 black-color ${isActiveQuery('rework-type', constant.BRAND_CATEGORY[0].value) ? 'active' : 'nav-deactive'}`}
                                        to={`/products/rework?rework-type=${constant.BRAND_CATEGORY[0].value}`}>
                                        <BsArrowLeftRight className='fs-20' />
                                        <li className='sidebar-list-styled'>Rework</li>
                                      </NavLink>}
                                      {checkPermissionsInArray(AllPermissions.Product_Pending_Tab) && <NavLink
                                        className={`s-sidebar__nav-link d-flex gap-3 align-items-center ps-6 text-decoration-none sidebar-menu-fs fs-14 black-color ${isActiveQuery('pending-type', constant.BRAND_CATEGORY[0].value) ? 'active' : 'nav-deactive'}`}
                                        to={`/products/pending?pending-type=${constant.BRAND_CATEGORY[0].value}`}>
                                        <BsArrowLeftRight className='fs-20' />
                                        <li className='sidebar-list-styled'>Pending</li>
                                      </NavLink>}
                                      {checkPermissionsInArray(AllPermissions.Product_Approved_Tab) && <NavLink
                                        className={`s-sidebar__nav-link d-flex gap-3 align-items-center ps-6 text-decoration-none sidebar-menu-fs fs-14 black-color ${isActiveQuery('approved-type', constant.BRAND_CATEGORY[0].value) ? 'active' : 'nav-deactive'}`}
                                        to={`/products/approved?approved-type=${constant.BRAND_CATEGORY[0].value}`}>
                                        <BsArrowLeftRight className='fs-20' />
                                        <li className='sidebar-list-styled'>Approved</li>
                                      </NavLink>}
                                      {checkPermissionsInArray(AllPermissions.Product_Bulk_Edit_Tab) && <NavLink
                                        className={`s-sidebar__nav-link d-flex gap-3 align-items-center ps-6 text-decoration-none sidebar-menu-fs fs-14 black-color ${isActiveQuery('bulk-edit-type', constant.BRAND_CATEGORY[0].value) ? 'active' : 'nav-deactive'}`}
                                        to={`/products/bulk-edit?bulk-edit-type=${constant.BRAND_CATEGORY[0].value}`}>
                                        <BsArrowLeftRight className='fs-20' />
                                        <li className='sidebar-list-styled'>Bulk Edit</li>
                                      </NavLink>}
                                      {checkPermissionsInArray(AllPermissions.Product_Bulk_Upload_Tab) && <NavLink
                                        className={`s-sidebar__nav-link d-flex gap-3 align-items-center ps-6 text-decoration-none sidebar-menu-fs fs-14 black-color ${isActiveQuery('bulk-upload-type', constant.BRAND_CATEGORY[0].value) ? 'active' : 'nav-deactive'}`}
                                        to={`/products/bulk-upload?bulk-upload-type=${constant.BRAND_CATEGORY[0].value}`}>
                                        <BsArrowLeftRight className='fs-20' />
                                        <li className='sidebar-list-styled'>Bulk Upload</li>
                                      </NavLink>}

                                      {/* TODO create the permission for Bulk filter and bulk Approve */}
                                      {checkPermissionsInArray(AllPermissions.Product_Bulk_Upload_Tab) && <NavLink
                                        className={`s-sidebar__nav-link d-flex gap-3 align-items-center ps-6 text-decoration-none sidebar-menu-fs fs-14 black-color ${isActiveQuery('bulk-filter-type', constant.BRAND_CATEGORY[0].value) ? 'active' : 'nav-deactive'}`}
                                        //permission is pending
                                        to={`/products/bulk-filter?bulk-filter-type=${constant.BRAND_CATEGORY[0].value}`}>
                                        <BsArrowLeftRight className='fs-20' />
                                        <li className='sidebar-list-styled'>Bulk Filter</li>
                                      </NavLink>}
                                      {checkPermissionsInArray(AllPermissions.Product_Bulk_Upload_Tab) && <NavLink
                                        className={`s-sidebar__nav-link d-flex gap-3 align-items-center ps-6 text-decoration-none sidebar-menu-fs fs-14 black-color ${isActiveQuery('bulk-approve-type', constant.BRAND_CATEGORY[0].value) ? 'active' : 'nav-deactive'}`}
                                        //permission is pending
                                        to={`/products/bulk-approve?bulk-approve-type=${constant.BRAND_CATEGORY[0].value}`}>
                                        <BsArrowLeftRight className='fs-20' />
                                        <li className='sidebar-list-styled'>Bulk Approve</li>
                                      </NavLink>}
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>}

                          {checkPermissionsInArray(AllPermissions.Inventory_Tab) && <li className="">
                            <div className="accordion" id="accordionExample">
                              <div className="accordion-item border-0">
                                <h2 className="accordion-header " id="headingTwo">
                                  <button
                                    className="collapsed accordion-button bg-body shadow-none s-sidebar__nav-link d-flex gap-2 align-items-center ps-5 text-decoration-none sidebar-menu-fs fs-14 black-color rounded-0"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseTwo"
                                    aria-expanded="false"
                                    aria-controls="collapseTwo"
                                  >
                                    <AiOutlineBarChart className='fs-18' />
                                    Inventory
                                    <Badge count={(notificationCount?.inventory_pending_count + notificationCount?.barcode_pending_count)} />
                                  </button>
                                </h2>
                                <div
                                  id="collapseTwo"
                                  className="accordion-collapse collapse"
                                  aria-labelledby="headingTwo"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div className="accordion-body p-0">
                                    <ul className="list-unstyled">

                                      {
                                        checkPermissionsInArray(AllPermissions.Inventory_Pending_Tab) &&
                                        <NavLink
                                          className={`s-sidebar__nav-link d-flex gap-3 align-items-center ps-6 text-decoration-none sidebar-menu-fs fs-14 black-color 
                                        ${isActiveQuery('pending-list-type', constant.BRAND_CATEGORY[0].value) ? 'active' : 'nav-deactive'}`}
                                          to={`/inventory/pending/list?pending-list-type=${constant.BRAND_CATEGORY[0].value}`}>
                                          <BsArrowLeftRight className='fs-20' />
                                          <li className='sidebar-list-styled overflow-hidden text-break'>Pending/Rework</li>
                                          <Badge count={notificationCount?.inventory_pending_count} className='translate-50' />
                                        </NavLink>}
                                      {
                                        checkPermissionsInArray(AllPermissions.Inventory_Approved_Tab) &&
                                        <NavLink
                                          className={`s-sidebar__nav-link d-flex gap-3 align-items-center ps-6 text-decoration-none sidebar-menu-fs fs-14 black-color 
                                        ${isActiveQuery('approve-list-type', constant.BRAND_CATEGORY[0].value) ? 'active' : 'nav-deactive'}`}
                                          to={`/inventory/approve/list?approve-list-type=${constant.BRAND_CATEGORY[0].value}`}>
                                          <BsArrowLeftRight className='fs-20' />
                                          <li className='sidebar-list-styled'>Approved</li>
                                        </NavLink>}
                                      {
                                        checkPermissionsInArray(AllPermissions.Inventory_Search_Tab) &&
                                        <NavLink
                                          className={`s-sidebar__nav-link d-flex gap-3 align-items-center ps-6 text-decoration-none sidebar-menu-fs fs-14 black-color 
                                        ${isActiveQuery('search-type', constant.BRAND_CATEGORY[0].value) ? 'active' : 'nav-deactive'}`}
                                          to={`/inventory/search?search-type=${constant.BRAND_CATEGORY[0].value}`}>
                                          <BsArrowLeftRight className='fs-20' />
                                          <li className='sidebar-list-styled'>Search</li>
                                        </NavLink>}
                                      {
                                        checkPermissionsInArray(AllPermissions.Inventory_MinQty_Tab) &&
                                        <NavLink
                                          className={`s-sidebar__nav-link d-flex gap-3 align-items-center ps-6 text-decoration-none sidebar-menu-fs fs-14 black-color 
                                        ${isActiveQuery('quantity-type', constant.BRAND_CATEGORY[0].value) ? 'active' : 'nav-deactive'}`}
                                          to={`/inventory/quantity?quantity-type=${constant.BRAND_CATEGORY[0].value}`}>
                                          <BsArrowLeftRight className='fs-20' />
                                          <li className='sidebar-list-styled'>Visible Min Qty</li>
                                        </NavLink>}
                                      {
                                        checkPermissionsInArray(AllPermissions.Barcode_Pending) &&
                                        <NavLink
                                          className={`s-sidebar__nav-link d-flex gap-3 align-items-center ps-6 text-decoration-none sidebar-menu-fs fs-14 black-color 
                                        ${isActiveQuery('status-pending-type', constant.BRAND_CATEGORY[0].value) ? 'active' : 'nav-deactive'}`}
                                          to={`/inventory/barcode-status/pending?status-pending-type=${constant.BRAND_CATEGORY[0].value}`}>
                                          <BsArrowLeftRight className='fs-20' />
                                          <li className='sidebar-list-styled'>Pending Barcode</li>
                                          <Badge count={notificationCount?.barcode_pending_count} className='translate-50' />
                                        </NavLink>}
                                      {
                                        checkPermissionsInArray(AllPermissions.Barcode_Approved) &&
                                        <NavLink
                                          className={`s-sidebar__nav-link d-flex gap-3 align-items-center ps-6 text-decoration-none sidebar-menu-fs fs-14 black-color 
                                        ${isActiveQuery('status-approve-type', constant.BRAND_CATEGORY[0].value) ? 'active' : 'nav-deactive'}`}
                                          to={`/inventory/barcode-status/approved?status-approve-type=${constant.BRAND_CATEGORY[0].value}`}>
                                          <BsArrowLeftRight className='fs-20' />
                                          <li className='sidebar-list-styled'>Approve Barcode</li>
                                        </NavLink>}
                                      {/* TODO  make permission for price updated Barcode */}
                                      {
                                        checkPermissionsInArray(AllPermissions.Barcode_Approved) &&
                                        <NavLink
                                          className={`s-sidebar__nav-link d-flex gap-3 align-items-center ps-6 text-decoration-none sidebar-menu-fs fs-14 black-color 
                                        ${isActiveQuery('status-price-updated-type', constant.BRAND_CATEGORY[0].value) ? 'active' : 'nav-deactive'}`}
                                          to={`/inventory/barcode-status/price-updated?status-price-updated-type=${constant.BRAND_CATEGORY[0].value}`}>
                                          <BsArrowLeftRight className='fs-20' />
                                          <li className='sidebar-list-styled'>Price Updated Barcode</li>
                                        </NavLink>
                                      }
                                      {/* TODO make permission for MoveOut Barcode */}
                                      {
                                        checkPermissionsInArray(AllPermissions.Barcode_Approved) &&
                                        <NavLink
                                          className={`s-sidebar__nav-link d-flex gap-3 align-items-center ps-6 text-decoration-none sidebar-menu-fs fs-14 black-color 
                                        ${isActiveQuery('status-moveout-type', constant.BRAND_CATEGORY[0].value) ? 'active' : 'nav-deactive'}`}
                                          to={`/inventory/barcode-status/move-out?status-moveout-type=${constant.BRAND_CATEGORY[0].value}`}>
                                          <BsArrowLeftRight className='fs-20' />
                                          <li className='sidebar-list-styled'>Moved Out</li>
                                        </NavLink>}
                                      {
                                        checkPermissionsInArray(AllPermissions.Inventory_Audit_Tab) &&
                                        <NavLink
                                          className={`s-sidebar__nav-link d-flex gap-3 align-items-center ps-6 text-decoration-none sidebar-menu-fs fs-14 black-color 
                                        ${isActiveQuery('audit-type', constant.BRAND_CATEGORY[0].value) ? 'active' : 'nav-deactive'}`}
                                          to={`/store/inventory-audit?audit-type=${constant.BRAND_CATEGORY[0].value}`}>
                                          <BsArrowLeftRight className='fs-20' />
                                          <li className='sidebar-list-styled'>
                                            Inventory Audit
                                          </li>
                                        </NavLink>}
                                      {/* TODO make permission for lost barcode and Lost barcode History */}
                                      {
                                        checkPermissionsInArray(AllPermissions.Inventory_Audit_Tab) &&
                                        <NavLink
                                          className={`s-sidebar__nav-link d-flex gap-3 align-items-center ps-6 text-decoration-none sidebar-menu-fs fs-14 black-color 
                                        ${isActiveQuery('lost-barcode-type', constant.BRAND_CATEGORY[0].value) ? 'active' : 'nav-deactive'}`}
                                          to={`/inventory/lost-barcode?lost-barcode-type=${constant.BRAND_CATEGORY[0].value}`}>
                                          <BsArrowLeftRight className='fs-20' />
                                          <li className='sidebar-list-styled'>
                                            Lost Barcode
                                          </li>
                                        </NavLink>}
                                      {
                                        checkPermissionsInArray(AllPermissions.Inventory_Audit_Tab) &&
                                        <NavLink
                                          className={`s-sidebar__nav-link d-flex gap-3 align-items-center ps-6 text-decoration-none sidebar-menu-fs fs-14 black-color 
                                        ${isActiveQuery('history-type', constant.BRAND_CATEGORY[0].value) ? 'active' : 'nav-deactive'}`}
                                          to={`/inventory/lost-barcode-history?history-type=${constant.BRAND_CATEGORY[0].value}`}>
                                          <BsArrowLeftRight className='fs-20' />
                                          <li className='sidebar-list-styled'>
                                            Lost Barcode History
                                          </li>
                                        </NavLink>}
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </li>

              {/* TODO make permission for this new section Tab */}
              {checkPermissionsInArray(AllPermissions.Movement_Tab) &&
                <li>
                  <div className="accordion" id="storeStatus">
                    <div className="accordion-item border-0">
                      <h2 className="accordion-header " id="headingStore">
                        <button
                          className="collapsed accordion-button bg-body shadow-none s-sidebar__nav-link d-flex gap-3 align-items-center ps-4 text-decoration-none sidebar-menu-fs fs-14 black-color rounded-0"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseStore"
                          aria-expanded="false"
                          aria-controls="collapseStore"
                        >
                          <LiaStoreAltSolid className='fs-18' />
                          Store
                        </button>
                      </h2>
                      <div
                        id="collapseStore"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingsStore"
                        data-bs-parent="#storeStatus"
                      >
                        <div className="accordion-body p-0">
                          <ul className="list-unstyled">
                            {
                              checkPermissionsInArray(AllPermissions.Store_Tab) &&
                              <NavLink className="s-sidebar__nav-link d-flex gap-3 align-items-center ps-5 text-decoration-none sidebar-menu-fs fs-14 black-color"
                                to="/stores">
                                <BsArrowLeftRight className='fs-20' />
                                <li className='sidebar-list-styled'>
                                  Add Store
                                </li>
                              </NavLink>}
                            {
                              checkPermissionsInArray(AllPermissions.Allocation_Tab) &&
                              <NavLink className="s-sidebar__nav-link d-flex gap-3 align-items-center ps-5 text-decoration-none sidebar-menu-fs fs-14 black-color"
                                to="/store/brand/allocation/add">
                                <BsArrowLeftRight className='fs-20' />
                                <li className='sidebar-list-styled'>
                                  Brand Allocation
                                </li>
                              </NavLink>}
                            {
                              checkPermissionsInArray(AllPermissions.Allocated_Tab) &&
                              <NavLink className="s-sidebar__nav-link d-flex gap-3 align-items-center ps-5 text-decoration-none sidebar-menu-fs fs-14 black-color"
                                to="/store/brand/allocation-store">
                                <BsArrowLeftRight className='fs-20' />
                                <li className='sidebar-list-styled'>
                                  Allocated Brands
                                </li>
                              </NavLink>}
                            {
                              checkPermissionsInArray(AllPermissions.Procurement_Tab) &&
                              <NavLink className="s-sidebar__nav-link d-flex gap-3 align-items-center ps-5 text-decoration-none sidebar-menu-fs fs-14 black-color"
                                to="/store/procurement">
                                <BsArrowLeftRight className='fs-20' />
                                <li className='sidebar-list-styled'>
                                  Procurement
                                </li>
                              </NavLink>}
                            {/* {
                              checkPermissionsInArray(AllPermissions.Movement_Pending_Tab) &&
                              <NavLink className={`s-sidebar__nav-link d-flex gap-3 align-items-center ps-5 text-decoration-none sidebar-menu-fs fs-14 black-color ${sidebar && "active"} `}
                                to="/movement/pending">
                                <BsArrowLeftRight className='fs-20' />
                                <li className='sidebar-list-styled'>
                                  Pending
                                  <Badge count={notificationCount?.movement_pending_count} />
                                </li>
                              </NavLink>} */}

                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>}

              {checkPermissionsInArray(AllPermissions.Movement_Tab) &&
                <li>
                  <div className="accordion" id="movementStatus">
                    <div className="accordion-item border-0">
                      <h2 className="accordion-header " id="headingThree">
                        <button
                          className="collapsed accordion-button bg-body shadow-none s-sidebar__nav-link d-flex gap-3 align-items-center ps-4 text-decoration-none sidebar-menu-fs fs-14 black-color rounded-0"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseThree3"
                          aria-expanded="false"
                          aria-controls="collapseThree3"
                        >
                          <TiArrowRepeat className='fs-18' />
                          Movement
                          <Badge count={((notificationCount?.movement_pending_count || 0) + (notificationCount?.movement_processed_count || 0) + (notificationCount?.movement_procurement_count || 0))} />
                        </button>
                      </h2>
                      <div
                        id="collapseThree3"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingThree"
                        data-bs-parent="#movementStatus"
                      >
                        <div className="accordion-body p-0">
                          <ul className="list-unstyled">
                            {
                              checkPermissionsInArray(AllPermissions.Movement_Movement_Tab) &&
                              <NavLink className="s-sidebar__nav-link d-flex gap-3 align-items-center ps-5 text-decoration-none sidebar-menu-fs fs-14 black-color"
                                to="/movement/list">
                                <BsArrowLeftRight className='fs-20' />
                                <li className='sidebar-list-styled'>
                                  Movement
                                  <Badge count={notificationCount?.movement_procurement_count} />
                                </li>
                              </NavLink>}
                            {
                              checkPermissionsInArray(AllPermissions.Movement_Pending_Tab) &&
                              <NavLink className={`s-sidebar__nav-link d-flex gap-3 align-items-center ps-5 text-decoration-none sidebar-menu-fs fs-14 black-color ${sidebar && "active"} `}
                                to="/movement/pending">
                                <BsArrowLeftRight className='fs-20' />
                                <li className='sidebar-list-styled'>
                                  Pending
                                  <Badge count={notificationCount?.movement_pending_count} />
                                </li>
                              </NavLink>}
                            {
                              checkPermissionsInArray(AllPermissions.Movement_InProcess_Tab) &&
                              <NavLink className={`s-sidebar__nav-link d-flex gap-3 align-items-center ps-5 text-decoration-none sidebar-menu-fs fs-14 black-color ${sidebar && "active"} `}
                                to="/movement/in-process">
                                <BsArrowLeftRight className='fs-20' />
                                <li className='sidebar-list-styled'>
                                  In-process
                                  <Badge count={notificationCount?.movement_processed_count} />
                                </li>
                              </NavLink>}
                            {
                              checkPermissionsInArray(AllPermissions.Movement_Delivered_Tab) &&
                              <NavLink className={`s-sidebar__nav-link d-flex gap-3 align-items-center ps-5 text-decoration-none sidebar-menu-fs fs-14 black-color ${sidebar && "active"} `}
                                to="/movement/delivered">
                                <BsArrowLeftRight className='fs-20' />
                                <li className='sidebar-list-styled'>Delivered</li>
                              </NavLink>}
                            {
                              checkPermissionsInArray(AllPermissions.Movement_Cancelled_Tab) &&
                              <NavLink className={`s-sidebar__nav-link d-flex gap-3 align-items-center ps-5 text-decoration-none sidebar-menu-fs fs-14 black-color ${sidebar && "active"} `}
                                to="/movement/cancelled">
                                <BsArrowLeftRight className='fs-20' />
                                <li className='sidebar-list-styled'>Cancelled</li>
                              </NavLink>}

                            {/* TODO make permission for Settlement Notes and History */}
                            {checkPermissionsInArray(AllPermissions.Report_Barcode_Audit_Report_Tab) &&
                              <NavLink className="s-sidebar__nav-link d-flex gap-3 align-items-center ps-5 text-decoration-none sidebar-menu-fs fs-14 black-color"
                                to="/movement/settlement-note">
                                <BsArrowLeftRight className='fs-20' />
                                <li className='sidebar-list-styled'>Settlement Note</li>
                              </NavLink>}
                            {checkPermissionsInArray(AllPermissions.Report_Barcode_Audit_Report_Tab) &&
                              <NavLink className="s-sidebar__nav-link d-flex gap-3 align-items-center ps-5 text-decoration-none sidebar-menu-fs fs-14 black-color"
                                to="/movement/settlement-history">
                                <BsArrowLeftRight className='fs-20' />
                                <li className='sidebar-list-styled'>Settlement History</li>
                              </NavLink>}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>}

              {checkPermissionsInArray(AllPermissions.Warranty_Tab) && <li>
                <NavLink className="s-sidebar__nav-link d-flex gap-3 align-items-center ps-4 text-decoration-none sidebar-menu-fs fs-14 black-color" to="/warranty">
                  <span><RxDashboard className='fs-18' /></span>Warrantee
                </NavLink>
              </li>}

              {checkPermissionsInArray(AllPermissions.Accounts_Tab) && <li>
                <div className="accordion" id="accordionExample">
                  <div className="accordion-item border-0">
                    <h2 className="accordion-header " id="accountsId">
                      <button
                        className="collapsed accordion-button bg-body shadow-none s-sidebar__nav-link d-flex gap-3 align-items-center ps-4 text-decoration-none sidebar-menu-fs fs-14 black-color rounded-0"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseAccounts"
                        aria-expanded="false"
                        aria-controls="collapseAccounts"
                      >
                        <MdOutlineAccountBalanceWallet className='fs-18' />
                        Accounts
                      </button>
                    </h2>
                    <div
                      id="collapseAccounts"
                      className="accordion-collapse collapse"
                      aria-labelledby="accountsId"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body p-0">
                        <ul className="list-unstyled">
                          {checkPermissionsInArray(AllPermissions.ALL_ENTRIES_Tab) &&
                            <NavLink className="s-sidebar__nav-link d-flex gap-3 align-items-center ps-5 text-decoration-none sidebar-menu-fs fs-14 black-color"
                              to="/accounts/all-entries">
                              <BsArrowLeftRight className='fs-20' />
                              <li className='sidebar-list-styled'>All Entries</li>
                            </NavLink>}
                          {checkPermissionsInArray(AllPermissions.Sub_Accounts_Tab) &&
                            <NavLink className="s-sidebar__nav-link d-flex gap-3 align-items-center ps-5 text-decoration-none sidebar-menu-fs fs-14 black-color"
                              to="/accounts/sub-account">
                              <BsArrowLeftRight className='fs-20' />
                              <li className='sidebar-list-styled'>Sub Types</li>
                            </NavLink>}
                          {checkPermissionsInArray(AllPermissions.General_Accounts_Tab) &&
                            <NavLink className="s-sidebar__nav-link d-flex gap-3 align-items-center ps-5 text-decoration-none sidebar-menu-fs fs-14 black-color"
                              to="/accounts/general-account">
                              <BsArrowLeftRight className='fs-20' />
                              <li className='sidebar-list-styled'>General Accounts</li>
                            </NavLink>}

                          {checkPermissionsInArray(AllPermissions.Trail_Balance_Tab) &&
                            <NavLink className="s-sidebar__nav-link d-flex gap-3 align-items-center ps-5 text-decoration-none sidebar-menu-fs fs-14 black-color"
                              to='/accounts/trail-balance-report'>
                              <BsArrowLeftRight className='fs-20' />
                              <li className='sidebar-list-styled'>Trail Balance Report</li>
                            </NavLink>}
                          {checkPermissionsInArray(AllPermissions.Gl_Report_Tab) &&
                            <NavLink className="s-sidebar__nav-link d-flex gap-3 align-items-center ps-5 text-decoration-none sidebar-menu-fs fs-14 black-color"
                              to="/accounts/general-report">
                              <BsArrowLeftRight className='fs-20' />
                              <li className='sidebar-list-styled'>GL Report</li>
                            </NavLink>}
                          {checkPermissionsInArray(AllPermissions.Transaction_Tab) &&
                            <NavLink className="s-sidebar__nav-link d-flex gap-3 align-items-center ps-5 text-decoration-none sidebar-menu-fs fs-14 black-color"
                              to="/accounts/transaction-report">
                              <BsArrowLeftRight className='fs-20' />
                              <li className='sidebar-list-styled'>Transaction Report</li>
                            </NavLink>}
                          {checkPermissionsInArray(AllPermissions.Store_Balance_Tab) &&
                            <NavLink className="s-sidebar__nav-link d-flex gap-3 align-items-center ps-5 text-decoration-none sidebar-menu-fs fs-14 black-color"
                              to="/accounts/store-balance-report">
                              <BsArrowLeftRight className='fs-20' />
                              <li className='sidebar-list-styled'>Store Balance Report</li>
                            </NavLink>}
                          {/* TODO make permission for add Voucher page */}
                          {checkPermissionsInArray(AllPermissions.General_Accounts_Tab) &&
                            <NavLink className="s-sidebar__nav-link d-flex gap-3 align-items-center ps-5 text-decoration-none sidebar-menu-fs fs-14 black-color"
                              to="/accounts/add-voucher">
                              <BsArrowLeftRight className='fs-20' />
                              <li className='sidebar-list-styled'>Add Voucher</li>
                            </NavLink>}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </li>}

              {checkPermissionsInArray(AllPermissions.Report_Tab) && <li>
                <div className="accordion" id="accordionExample">
                  <div className="accordion-item border-0">
                    <h2 className="accordion-header " id="reportId">
                      <button
                        className="collapsed accordion-button bg-body shadow-none s-sidebar__nav-link d-flex gap-3 align-items-center ps-4 text-decoration-none sidebar-menu-fs fs-14 black-color rounded-0"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseReport"
                        aria-expanded="false"
                        aria-controls="collapseReport"
                      >
                        <TbReport className='fs-18' />
                        Report
                      </button>
                    </h2>
                    <div
                      id="collapseReport"
                      className="accordion-collapse collapse"
                      aria-labelledby="reportId"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body p-0">
                        <ul className="list-unstyled">
                          {checkPermissionsInArray(AllPermissions.Report_Customer_Report_Tab) &&
                            <NavLink className="s-sidebar__nav-link d-flex gap-3 align-items-center ps-5 text-decoration-none sidebar-menu-fs fs-14 black-color"
                              to="/report/customer">
                              <BsArrowLeftRight className='fs-20' />
                              <li className='sidebar-list-styled'>Customer Report</li>
                            </NavLink>}
                          {checkPermissionsInArray(AllPermissions.Report_GST_Input_Report_Tab) &&
                            <NavLink className="s-sidebar__nav-link d-flex gap-3 align-items-center ps-5 text-decoration-none sidebar-menu-fs fs-14 black-color"
                              to="/report/purchased">
                              <BsArrowLeftRight className='fs-20' />
                              <li className='sidebar-list-styled'>Purchase Report</li>
                            </NavLink>}

                          {checkPermissionsInArray(AllPermissions.Report_Store_Sales_Report_Tab) &&
                            <NavLink className="s-sidebar__nav-link d-flex gap-3 align-items-center ps-5 text-decoration-none sidebar-menu-fs fs-14 black-color"
                              to="/report/store-sales">
                              <BsArrowLeftRight className='fs-20' />
                              <li className='sidebar-list-styled'>Store Sales Report</li>
                            </NavLink>}
                          {checkPermissionsInArray(AllPermissions.Report_Store_Sales_Return_Report_Tab) &&
                            <NavLink className="s-sidebar__nav-link d-flex gap-3 align-items-center ps-5 text-decoration-none sidebar-menu-fs fs-14 black-color"
                              to="/report/store-sales-return">
                              <BsArrowLeftRight className='fs-20' />
                              <li className='sidebar-list-styled'>Store Sales Return Report</li>
                            </NavLink>}

                          {checkPermissionsInArray(AllPermissions.Report_Inventory_Movement_Report_Tab) &&
                            <NavLink className="s-sidebar__nav-link d-flex gap-3 align-items-center ps-5 text-decoration-none sidebar-menu-fs fs-14 black-color"
                              to="/report/inventory-movement">
                              <BsArrowLeftRight className='fs-20' />
                              <li className='sidebar-list-styled'>Inventory Movement Report</li>
                            </NavLink>}

                          {checkPermissionsInArray(AllPermissions.Report_Inventory_Report_Tab) &&
                            <NavLink className="s-sidebar__nav-link d-flex gap-3 align-items-center ps-5 text-decoration-none sidebar-menu-fs fs-14 black-color"
                              to="/report/inventory">
                              <BsArrowLeftRight className='fs-20' />
                              <li className='sidebar-list-styled'>Inventory Report</li>
                            </NavLink>}

                          {checkPermissionsInArray(AllPermissions.Report_GST_Output_Report_Tab) &&
                            <NavLink className="s-sidebar__nav-link d-flex gap-3 align-items-center ps-5 text-decoration-none sidebar-menu-fs fs-14 black-color"
                              to="/report/gst-output">
                              <BsArrowLeftRight className='fs-20' />
                              <li className='sidebar-list-styled'>GST Output Report</li>
                            </NavLink>}

                          {checkPermissionsInArray(AllPermissions.Report_Store_Report_Tab) &&
                            <NavLink className="s-sidebar__nav-link d-flex gap-3 align-items-center ps-5 text-decoration-none sidebar-menu-fs fs-14 black-color"
                              to="/report/store">
                              <BsArrowLeftRight className='fs-20' />
                              <li className='sidebar-list-styled'>Store Report</li>
                            </NavLink>}

                          {checkPermissionsInArray(AllPermissions.Report_Inventory_Addition_Tab) &&
                            <NavLink className="s-sidebar__nav-link d-flex gap-3 align-items-center ps-5 text-decoration-none sidebar-menu-fs fs-14 black-color"
                              to="/report/inventory-addition">
                              <BsArrowLeftRight className='fs-20' />
                              <li className='sidebar-list-styled'>Inventory Addition Report</li>
                            </NavLink>}

                          {checkPermissionsInArray(AllPermissions.Report_Invoicing_Report_Tab) &&
                            <NavLink className="s-sidebar__nav-link d-flex gap-3 align-items-center ps-5 text-decoration-none sidebar-menu-fs fs-14 black-color"
                              to="/report/invoicing">
                              <BsArrowLeftRight className='fs-20' />
                              <li className='sidebar-list-styled'>Invoicing Report</li>
                            </NavLink>}
                          {checkPermissionsInArray(AllPermissions.Report_Invoicing_Return_Report_Tab) &&
                            <NavLink className="s-sidebar__nav-link d-flex gap-3 align-items-center ps-5 text-decoration-none sidebar-menu-fs fs-14 black-color"
                              to="/report/invoicing-return">
                              <BsArrowLeftRight className='fs-20' />
                              <li className='sidebar-list-styled'>Invoicing Return Report</li>
                            </NavLink>}

                          {checkPermissionsInArray(AllPermissions.Report_Barcode_Audit_Report_Tab) &&
                            <NavLink className="s-sidebar__nav-link d-flex gap-3 align-items-center ps-5 text-decoration-none sidebar-menu-fs fs-14 black-color"
                              to="/report/barcode-audit-trail">
                              <BsArrowLeftRight className='fs-20' />
                              <li className='sidebar-list-styled'>Barcode Audit Trail Report</li>
                            </NavLink>}

                          {/* TODO make permission for Ownership Transfer Report */}
                          {checkPermissionsInArray(AllPermissions.Report_Barcode_Audit_Report_Tab) &&
                            <NavLink className="s-sidebar__nav-link d-flex gap-3 align-items-center ps-5 text-decoration-none sidebar-menu-fs fs-14 black-color"
                              to='/report/ownership-transfer-report'>
                              <BsArrowLeftRight className='fs-20' />
                              <li className='sidebar-list-styled'>Ownership Transfer Report</li>
                            </NavLink>}

                          {checkPermissionsInArray(AllPermissions.Loyalty_Points_Tab) &&
                            <NavLink className="s-sidebar__nav-link d-flex gap-3 align-items-center ps-5 text-decoration-none sidebar-menu-fs fs-14 black-color"
                              to="/report/loyalty-points">
                              <BsArrowLeftRight className='fs-20' />
                              <li className='sidebar-list-styled'>Loyalty Points</li>
                            </NavLink>}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </li>}

              {checkPermissionsInArray(AllPermissions.Settings_Tab) && <li>
                <div className="accordion" id="brandallocation">
                  <div className="accordion-item border-0">
                    <h2 className="accordion-header " id="pending">
                      <button
                        className="collapsed accordion-button bg-body shadow-none s-sidebar__nav-link d-flex gap-3 align-items-center ps-4 text-decoration-none sidebar-menu-fs fs-14 black-color rounded-0"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo2"
                        aria-expanded="false"
                        aria-controls="collapseTwo2"
                      >
                        <IoSettingsOutline className='fs-18' />
                        Setting
                      </button>
                    </h2>
                    <div
                      id="collapseTwo2"
                      className="accordion-collapse collapse"
                      aria-labelledby="pending"
                      data-bs-parent="#brandallocation"
                    >
                      <div className="accordion-body p-0">
                        <ul className="list-unstyled">
                          {checkPermissionsInArray(AllPermissions.Sub_Admin_Tab) && <NavLink className="s-sidebar__nav-link d-flex gap-3 align-items-center ps-5 text-decoration-none sidebar-menu-fs fs-14 black-color"
                            to="/sub-admin">
                            <BsArrowLeftRight className='fs-20' />
                            <li className='sidebar-list-styled'>Sub Admin</li>
                          </NavLink>}
                          {checkPermissionsInArray(AllPermissions.Roles_Tab) && <NavLink className="s-sidebar__nav-link d-flex gap-3 align-items-center ps-5 text-decoration-none sidebar-menu-fs fs-14 black-color"
                            to="/setting/role">
                            <BsArrowLeftRight className='fs-20' />
                            <li className='sidebar-list-styled'>Roles</li>
                          </NavLink>}
                          {checkPermissionsInArray(AllPermissions.Permissions_Tab) && <NavLink className="s-sidebar__nav-link d-flex gap-3 align-items-center ps-5 text-decoration-none sidebar-menu-fs fs-14 black-color"
                            to="/permissions">
                            <BsArrowLeftRight className='fs-20' />
                            <li className='sidebar-list-styled'>Permission</li>
                          </NavLink>}
                          {checkPermissionsInArray(AllPermissions.Loyalty_Tab) && <NavLink className="s-sidebar__nav-link d-flex gap-3 align-items-center ps-5 text-decoration-none sidebar-menu-fs fs-14 black-color"
                            to="/setting/loyalty/list">
                            <BsArrowLeftRight className='fs-20' />
                            <li className='sidebar-list-styled'>Other</li>
                          </NavLink>}
                          {checkPermissionsInArray(AllPermissions.Loyalty_Tab) && <NavLink className="s-sidebar__nav-link d-flex gap-3 align-items-center ps-5 text-decoration-none sidebar-menu-fs fs-14 black-color"
                            to="/setting/location-group">
                            <BsArrowLeftRight className='fs-20' />
                            <li className='sidebar-list-styled'>Location Grouping</li>
                          </NavLink>}
                          {checkPermissionsInArray(AllPermissions.Loyalty_Tab) && <NavLink className="s-sidebar__nav-link d-flex gap-3 align-items-center ps-5 text-decoration-none sidebar-menu-fs fs-14 black-color"
                            to="/setting/location-matrix">
                            <BsArrowLeftRight className='fs-20' />
                            <li className='sidebar-list-styled'>Location Matrix</li>
                          </NavLink>}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </li>}

              {/* TODO make permission of website form page */}
              {(checkPermissionsInArray(AllPermissions.Settings_Tab)) && <li>
                <div className="accordion" id="websiteForm">
                  <div className="accordion-item border-0">
                    <h2 className="accordion-header " id="pending">
                      <button
                        className="collapsed accordion-button bg-body shadow-none s-sidebar__nav-link d-flex gap-3 align-items-center ps-4 text-decoration-none sidebar-menu-fs fs-14 black-color rounded-0"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFeedback"
                        aria-expanded="false"
                        aria-controls="collapseFeedback"
                      >
                        <CgWebsite className='fs-18' />
                        Website Form
                      </button>
                    </h2>
                    <div
                      id="collapseFeedback"
                      className="accordion-collapse collapse"
                      aria-labelledby="pending"
                      data-bs-parent="#websiteForm"
                    >
                      <div className="accordion-body p-0">
                        <ul className="list-unstyled">
                          {checkPermissionsInArray(AllPermissions.Sub_Admin_Tab) && <NavLink className="s-sidebar__nav-link d-flex gap-3 align-items-center ps-5 text-decoration-none sidebar-menu-fs fs-14 black-color"
                            to="/feedback">
                            <BsArrowLeftRight className='fs-20' />
                            <li className='sidebar-list-styled'>Feedback Form</li>
                          </NavLink>}
                          {checkPermissionsInArray(AllPermissions.Roles_Tab) && <NavLink className="s-sidebar__nav-link d-flex gap-3 align-items-center ps-5 text-decoration-none sidebar-menu-fs fs-14 black-color"
                            to="/retailer">
                            <BsArrowLeftRight className='fs-20' />
                            <li className='sidebar-list-styled'>Retailer Form</li>
                          </NavLink>}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </li>}


              {/* {checkPermissionsInArray(AllPermissions.Sub_Admin_Tab) && <li className="">
                <NavLink className="s-sidebar__nav-link d-flex gap-3 align-items-center ps-4 text-decoration-none sidebar-menu-fs fs-14 black-color" to="/sub-admin">
                  <span><BsPerson className='fs-18' /></span>Sub Admin
                </NavLink>
              </li>} */}
              {/* {checkPermissionsInArray(AllPermissions.Supplier_Tab) && <li className="">
                <NavLink className="s-sidebar__nav-link d-flex gap-3 align-items-center ps-4 text-decoration-none sidebar-menu-fs fs-14 black-color" to="/supplier">
                  <span><AiOutlineShop className='fs-18' /></span>Supplier
                </NavLink>
              </li>} */}
              {/* {checkPermissionsInArray(AllPermissions.Barcode_Tab) && <li>
                <div className="accordion" id="barcodeStatus">
                  <div className="accordion-item border-0">
                    <h2 className="accordion-header " id="pending">
                      <button
                        className="collapsed accordion-button bg-body shadow-none s-sidebar__nav-link d-flex gap-3 align-items-center ps-4 text-decoration-none sidebar-menu-fs fs-14 black-color rounded-0"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne1"
                        aria-expanded="false"
                        aria-controls="collapseOne1"
                      >
                        <AiOutlineBarcode className='fs-18' />
                        Barcode Status
                        <Badge count={notificationCount?.barcode_pending_count} className="position-absolute translate-absolute top-2" style={{ left: "55%" }} />
                      </button>
                    </h2>
                    <div
                      id="collapseOne1"
                      className="accordion-collapse collapse"
                      aria-labelledby="pending"
                      data-bs-parent="#barcodeStatus"
                    >
                      <div className="accordion-body p-0">
                        <ul className="list-unstyled">
                          {checkPermissionsInArray(AllPermissions.Barcode_Pending) && <NavLink className="s-sidebar__nav-link d-flex gap-3 align-items-center ps-5 text-decoration-none sidebar-menu-fs fs-14 black-color"
                            to="/barcode-status/pending">
                            <BsArrowLeftRight className='fs-20' />
                            <li className='sidebar-list-styled'>
                              Pending
                              <Badge count={notificationCount?.barcode_pending_count} />
                            </li>
                          </NavLink>}
                          {checkPermissionsInArray(AllPermissions.Barcode_Approved) && <NavLink className="s-sidebar__nav-link d-flex gap-3 align-items-center ps-5 text-decoration-none sidebar-menu-fs fs-14 black-color"
                            to="/barcode-status/approved">
                            <BsArrowLeftRight className='fs-18' />
                            <li className='sidebar-list-styled'>Approved</li>
                          </NavLink>}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </li>} */}
              {/* {checkPermissionsInArray(AllPermissions.Brand_Allocation_Tab) && <li>
                <div className="accordion" id="brandallocation">
                  <div className="accordion-item border-0">
                    <h2 className="accordion-header " id="pending">
                      <button
                        className="collapsed accordion-button bg-body shadow-none s-sidebar__nav-link d-flex gap-3 align-items-center ps-4 text-decoration-none sidebar-menu-fs fs-14 black-color rounded-0"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo2"
                        aria-expanded="false"
                        aria-controls="collapseTwo2"
                      >
                        <BsPostage className='fs-18' />
                        Brand Allocation
                      </button>
                    </h2>
                    <div
                      id="collapseTwo2"
                      className="accordion-collapse collapse"
                      aria-labelledby="pending"
                      data-bs-parent="#brandallocation"
                    >
                      <div className="accordion-body p-0">
                        <ul className="list-unstyled">
                          {checkPermissionsInArray(AllPermissions.Allocation_Tab) && <NavLink className="s-sidebar__nav-link d-flex gap-3 align-items-center ps-5 text-decoration-none sidebar-menu-fs fs-14 black-color"
                            to="/brand/allocation/add">
                            <BsArrowLeftRight className='fs-20' />
                            <li className='sidebar-list-styled'>Allocation</li>
                          </NavLink>}
                          {checkPermissionsInArray(AllPermissions.Allocated_Tab) && <NavLink className={`s-sidebar__nav-link d-flex gap-3 align-items-center ps-5 text-decoration-none sidebar-menu-fs fs-14 black-color ${sidebar && "active"} `}
                            to="/brand/allocation-store">
                            <BsArrowLeftRight className='fs-20' />
                            <li className='sidebar-list-styled'>Allocated store</li>
                          </NavLink>}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </li>} */}




              {/* {checkPermissionsInArray(AllPermissions.Roles_Tab) && <li className="">
                <NavLink className="s-sidebar__nav-link d-flex gap-3 align-items-center ps-4 text-decoration-none sidebar-menu-fs fs-14 black-color" to="/role">
                  <span><BiStore className='fs-18' /></span>Roles
                </NavLink>
              </li>} */}
              {/* {checkPermissionsInArray(AllPermissions.Permissions_Tab) && <li className="">
                <NavLink className="s-sidebar__nav-link d-flex gap-3 align-items-center ps-4 text-decoration-none sidebar-menu-fs fs-14 black-color" to="/permissions">
                  <span><BiStore className='fs-18' /></span>Permissions
                </NavLink>
              </li>} */}


              {/* {checkPermissionsInArray(AllPermissions.Procurement_Tab) && <li className="">
                <NavLink className="s-sidebar__nav-link d-flex gap-3 align-items-center ps-4 text-decoration-none sidebar-menu-fs fs-14 black-color" to="/procurement">
                  <span><BiStore className='fs-18' /></span>Procurement </NavLink>
              </li>} */}
              {/* {checkPermissionsInArray(AllPermissions.Loyalty_Points_Tab) && <li>
                <NavLink className="s-sidebar__nav-link d-flex gap-3 align-items-center ps-4 text-decoration-none sidebar-menu-fs fs-14 black-color" to="/loyalty-points">
                  <span><RxDashboard className='fs-18' /></span>Loyalty points
                </NavLink>
              </li>} */}



              {/* {checkPermissionsInArray(AllPermissions.Inventory_Audit_Tab) && <li>
                <NavLink className="s-sidebar__nav-link d-flex gap-3 align-items-center ps-4 text-decoration-none sidebar-menu-fs fs-14 black-color" to='/inventory-audit'>
                  <span><RxDashboard className='fs-18' /></span>Inventory Audit
                </NavLink>
              </li>} */}
            </ul>
          </nav>
        </div>
      </div>
    </div >

  )
}

export default SideBar