import React, { useEffect, useRef, useState } from 'react';
import atoms from '../../atoms'
import molecules from '../../molecules'
import CopyIcon from '../../../assets/Images/copy-icon.svg'
import constant from '../../../constants/constant';
import { useParams } from "react-router-dom";
import CloseIcon from '../../../assets/Images/close.svg'
import { validationRules, validationRulesContactLens } from './validation';
import service from '../../../api/services';
import { Contact_lens, reading } from '../../../constants/defaultValues';
const { Image, Select, Input, Button, RadioInput }: any = atoms
const { ConfirmationModal, TopBar }: any = molecules
const AddProduct = ({ productType, brand, productShape, productMaterial, productColor, productData, isDuplicateValue, addProducts
    , setProductData, imageUrl, setDeleteClick, brandResponse, setProductType, getDropdown, glassSizes, power,
    templeSizes, bridgeSizes, isContactLensProduct, setIsContactLensProduct }: any) => {
    const fileInputRef = useRef<any>(null);
    const [previewImage, setPreviewImage] = useState([])
    const [errors, setErrors] = useState<any>({});
    const [hideDeletePopup, setHideDeletePopup] = useState(false)
    const [imageError, setImageError] = useState(false)
    const [imageFileStore, setImageFileStore] = useState<any>()

    let initialProductData: any = {
        isActive: false,
        product_id_display: '',
        modal_number: "",
        brand_color_code: '',
        brand_color_name: '',
        specx_colors: [],
        lens_colors: [],
        glass_size: '',
        temple_size: '',
        bridge_size: '',
        product_with_case: false,
        description: '',
        product_srp: '',
        features: [],
        rim_type: '',
        gender: [],
        warranty: '',
        weight: '',
        product_type_id: productData.product_type_id,
        brand_id: productData.brand_id,
        product_materials: [],
        shapes: [],
        images: [],
        cover_index: 0,
        isPolarised: '',
        productDescription: "",
        lens_type: '',
        lens_name: '',
        period_use: '',
        pack_of_quantity: 0,
        color_id: '',
    };
    let params = useParams();
    let productEditID = params.id || ''


    //     useEffect(()=>{
    // console.log("previewImagepreviewImage",previewImage,productData?.images)
    //     },[previewImage,productData?.images])

    const _handleImageChange = (event: any) => {
        setImageFileStore(event)
        const files = event.target.files;
        const updatedImages: any = [...productData.images];
        const updateImageUrl: any = [...previewImage];
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const imageUrl = URL.createObjectURL(file);
            updateImageUrl.push(imageUrl)
            updatedImages.push(file);
        }
        setPreviewImage(updateImageUrl)
        setProductData({ ...productData, images: updatedImages })
        setErrors((prevErrors: any) => ({ ...prevErrors, images: "" }));
    };
    const handleImageRemove = (image: any, index: number) => {
        if (fileInputRef && fileInputRef.current && fileInputRef.current.value == null) {
            fileInputRef.current.value = '';
            const tempStoreInputData = imageFileStore
            tempStoreInputData.target.value = ''
            setImageFileStore(tempStoreInputData)
        }
        let tempPreview = [...previewImage]
        let tempStoreImage = productData?.images
        tempPreview.splice(index, 1)
        setPreviewImage(tempPreview)
        tempStoreImage.splice(index, 1)
        setProductData({ ...productData, images: tempStoreImage })

        !isDuplicateValue && imagesDelete(image)

    }
    const _handelCurrentCover = (current: number) => {
        setProductData({ ...productData, cover_index: current })
    }
    useEffect(() => {
        setPreviewImage(imageUrl)
    }, [imageUrl])

    const imagesDelete = async (url: any) => {
        try {
            let payload = {
                "url": url,
                "product_id": params?.id
            }
            await service.product.deleteProductImages(payload)
        } catch (error: any) {
            console.log('error', error)
        }
    }

    const handleCheckContactLens = (value: any) => {
        if ((value?.label)?.toLowerCase() === Contact_lens) {
            setIsContactLensProduct(true);
        }else {
            setIsContactLensProduct(false);
        }
    }

    const handleChange = (name: string, value: any) => {

        setErrors((prevErrors: any) => ({ ...prevErrors, [name]: "" }));
        if (name === 'modal_number') {
            const containsUnderscore = /_/;
            if (containsUnderscore?.test(value)) {
                setErrors((prevErrors: any) => ({
                    ...prevErrors,
                    [name]: "Modal number not allow underscores."
                }));
                return
            }
        }
        setProductData((prevState: any) => ({
            ...prevState,
            [name]: value
        }));

    };

    const handleBrand = (value: any) => {
        if (brandResponse && value) {
            setProductData((prev: any) => ({ ...prev, product_type_id: "" }));
            const filteredData: any = brandResponse?.filter((item: any) => item?.id === value?.value);
            setProductType(getDropdown(filteredData[0]?.product_types))
        }
    }
    const validate = () => {
        let newErrors: any = {};
        const rules: any = isContactLensProduct ? validationRulesContactLens : validationRules

        for (const field in rules) {
            if (rules.hasOwnProperty(field)) {
                if (rules[field]?.required && (!productData[field] || productData[field]?.length === 0)) {
                    newErrors[field] = "This field is required.";
                } else if ((rules[field]?.label?.required) && (!productData[field]?.label)) {
                    newErrors[field] = "Please Select field";
                } else if ((rules[field][0]?.label?.required) && (!productData[field][0]?.label)) {
                    newErrors[field] = "Please Select field";
                    // } else if (productData[field]?.length === 0 && previewImage?.length === 0) {
                    //     newErrors[field] = "Please upload Images";
                }
            }
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = () => {
        const isValid = validate();
        if (isValid) {
            addProducts()
            // if (buttonClick) {
            //     setButtonClick(false)
            // } else {
            //     setButtonClick(true)
            // }
            // setButtonClick(true)
        }
    };

    useEffect(() => {
        getProductId()
        getDescription()
    }, [productData.brand_id, productData?.product_type_id, productData?.modal_number, productData?.rim_type, productData?.shapes, productData?.specx_colors, productData?.brand_color_name,
    productData?.brand_color_code, productData?.glass_size, productData?.isPolarised?.value, productData?.lens_name, productData?.period_use, productData?.lens_type, productData?.pack_of_quantity, productData?.color_id])

    const getProductId = () => {
        let tempProductId: any
        let tempBrandID = productData?.brand_id?.code ? productData.brand_id.code : '';
        let tempProductType = productData?.product_type_id?.code ? productData.product_type_id.code : '';
        if (isContactLensProduct) {
            let tempLensType = productData?.lens_type?.label ? productData?.lens_type?.label[0] : '';
            let tempPeriodUse = productData?.period_use?.label ? productData?.period_use?.label[0] : '';
            let tempLensName = productData?.lens_name ? productData?.lens_name : '';
            let tempPackQTY = productData?.pack_of_quantity;
            let tempColor = (productData?.color_id?.label && productData?.color_id?.label?.toLowerCase()) === 'white'
                ? '' : productData?.color_id?.label ? productData?.color_id?.label : '';
            tempProductId = ([tempBrandID, tempProductType, tempLensType, tempLensName,tempPeriodUse , tempPackQTY, tempColor].filter(Boolean).join('_')).toUpperCase();
        } else {
            let tempModalNumber = productData?.modal_number ? productData.modal_number : '';
            let tempColorCode = productData?.brand_color_code ? productData.brand_color_code : '';
            let temSize = productData?.glass_size ? productData?.glass_size?.value : '';
            let temPolorised = productData?.isPolarised?.value === 1 ? "P" : '';
            tempProductId = ([tempBrandID, tempProductType, tempModalNumber, tempColorCode, temSize, temPolorised].filter(Boolean).join('_')).toUpperCase();
        }

        setProductData((prevState: any) => ({ ...prevState, product_id_display: tempProductId }));
    }

    const getDescription = () => {
        let tempDescription: any
        let tempBrandID = productData?.brand_id?.label ? productData.brand_id.label : '';
        let tempProductType = productData?.product_type_id?.label ? productData.product_type_id.label.toUpperCase() : '';
        if (isContactLensProduct) {
            let tempLensType = productData?.lens_type?.label ? productData?.lens_type?.label : '';
            let tempPeriodUse = productData?.period_use?.label ? productData?.period_use?.label : '';
            let tempLensName = productData?.lens_name ? productData?.lens_name : '';
            let tempPackQTY = productData?.pack_of_quantity;
            let tempColor = productData?.color_id?.label ? productData?.color_id?.label : '';
            tempDescription = [tempBrandID, tempLensName, tempPeriodUse, tempLensType, tempPackQTY, tempColor + '' + 'COLOR'];
        } else {
            let tempProductRimType = productData?.rim_type?.label ? productData.rim_type.label : '';
            let tempColorCode = productData?.brand_color_name || [];
            const colorCodeLabels = tempColorCode;
            let tempProductShape = productData?.shapes || [];
            const productShapeLabels = tempProductShape.map((shape: any) => shape.label).join(' ');
            let temPolorised = productData?.isPolarised?.value === 1 ? 'POLARIZED' : '';
            tempDescription = [tempBrandID, colorCodeLabels?.length > 0 ? colorCodeLabels + ' ' + 'COLOR' : '', productShapeLabels, tempProductRimType, temPolorised, tempProductType,];
        }
        tempDescription = tempDescription.filter((value: any) => value && typeof value === 'string' && value?.trim() !== '')

        if (productData?.productDescription?.split(',')?.length > 1) {
            const responseDescription = productData?.productDescription?.split(',')?.slice(1)?.join();
            const description = `${tempDescription?.map((word: any) => word?.toUpperCase())?.join(' ')} ${responseDescription?.toUpperCase()}`
            setProductData((prevState: any) => ({ ...prevState, description }));
        } else {
            const description = tempDescription?.map((word: any) => word?.toUpperCase())?.join(' ');
            setProductData((prevState: any) => ({ ...prevState, description }));
        }
    }

    const resetProductData = () => {
        initialProductData.product_type_id = '';
        setProductData(initialProductData);
        setIsContactLensProduct(false);
    };

    return (
        <div>
            <div className='row align-items-center mb-4'>
                <div className="col-6">
                    <TopBar heading={productEditID ? 'Update' : 'Add'} />
                </div>

                <div className=' col-6 d-flex justify-content-end align-item-end gap-3 align-items-center'>
                    <h2 className='mb-0 fs-18 fw-700 secondary-color '>Product ID:</h2>
                    <div className='d-flex w-auto justify-content-between align-items-center p-2 add-product-custom-div cursor-pointer' >
                        <h2 className='mb-0 fs-16 fw-600 secondary-color'>{productData.product_id_display}</h2>
                        <div className='ms-2'>
                            <Image src={CopyIcon} onClick={() => productData.product_id_display && navigator.clipboard.writeText(productData.product_id_display)} />
                        </div>
                    </div>

                </div>
            </div>
            {productEditID && <>
                <div className='d-flex gap-3 align-items-center mb-4 '>
                    <div className=''>
                        <label className='fs-16 fw-600  secondary-color ' htmlFor='true'>Product Status :</label>
                    </div>
                    <div className='d-flex gap-3'>
                        <RadioInput type="radio" className={"form-check-input"} checked={productData.isActive === true && true} label={"Activate"} onChange={(e: any) => { setProductData({ ...productData, isActive: true }) }} id={'activate'} />
                        <RadioInput type="radio" className={"form-check-input"} checked={productData.isActive === false && true} label={"Deactivate"} onChange={(e: any) => { setProductData({ ...productData, isActive: false }) }} id={'deactivate'} />
                    </div>
                </div></>}

            <div className='row mt-3'>
                <div className='col-4'>
                    <Select label={"Brand"} options={brand} className={"w-100"} placeholder={"Select Brand"} value={productData.brand_id}
                        onChange={(e: any) => { resetProductData(); handleChange("brand_id", e), handleBrand(e); }} errors={errors.brand_id}
                    />
                </div>
                <div className='col-4'>
                    <Select errors={errors.product_type_id} label={"Product Type"} options={productType} className={"w-100"} placeholder={"Select Type"}
                        value={productData.product_type_id || ''} onChange={(e: any) => { setProductData(initialProductData); handleChange("product_type_id", e); handleCheckContactLens(e) }}
                    />
                </div>
                {isContactLensProduct && <div className='col-4'>
                    <Select errors={errors.lens_type} label={"Lens Type"} options={constant.LENS_TYPE} className={"w-100"} placeholder={"Select Type"}
                        value={productData.lens_type || ''} onChange={(e: any) => { handleChange("lens_type", e); }}
                    />
                </div>}
                {!isContactLensProduct && <div className='col-4'>
                    <Input errors={errors.modal_number} label={"Model Number"} type={'text'} placeholder={"Enter Model Number"} value={productData.modal_number} onChange={(e: any) => { handleChange("modal_number", e.target.value) }} />
                </div>}
            </div>
            {!isContactLensProduct && <div className='row mt-3'>
                <div className='col-4'>
                    <Input errors={errors.brand_color_code} label={"Brand Color Code"} placeholder={"Enter Color Code"} value={productData.brand_color_code} onChange={(e: any) => {
                        handleChange("brand_color_code", e.target.value)
                    }} />
                </div>
                <div className='col-4'>
                    <Input errors={errors.brand_color_name} label={"Brand Color Name"} placeholder={"Enter Color Name"} value={productData.brand_color_name} onChange={(e: any) => { handleChange("brand_color_name", e.target.value) }} />
                </div>
                <div className='col-4'>
                    <Select
                        errors={errors.specx_colors}
                        label={"SW Color Name"}
                        placeholder={"Enter Color Name"}
                        className={"w-100"}
                        options={productColor}
                        isMulti={true}
                        value={productData.specx_colors}
                        onChange={(e: any) => { handleChange("specx_colors", e) }} />
                </div>

            </div>}

            <div className='row mt-3'>
                {!isContactLensProduct && <div className='col-4'>
                    <Select errors={errors.lens_colors} label={"SW Lens Color"} placeholder={"Enter Lens Color"} className={"w-100"} options={productColor} isMulti={true} value={productData.lens_colors}
                        onChange={(e: any) => { handleChange("lens_colors", e) }} />
                </div>}
                {isContactLensProduct && <div className='col-4'>
                    <Select
                        errors={errors.color_id}
                        label={" Color"}
                        placeholder={"Select Color"}
                        className={"w-100"}
                        options={productColor}
                        isMulti={false}
                        value={productData.color_id}
                        onChange={(e: any) => { handleChange("color_id", e) }} />
                </div>}
                {isContactLensProduct && <div className='col-4'>
                    <Input errors={errors.lens_name} label={"Lens Name"} placeholder={"Enter Lens Name"} value={productData.lens_name} onChange={(e: any) => { handleChange("lens_name", e.target.value) }} />
                </div>}
                {isContactLensProduct &&
                    <div className='col-4'>
                        <Select
                            errors={errors.period_use}
                            label={"Period Use"}
                            placeholder={"Enter Period Use"}
                            className={"w-100"}
                            options={constant.PERIOD_USE}
                            value={productData.period_use}
                            onChange={(e: any) => { handleChange("period_use", e) }} />
                    </div>
                }
                {!isContactLensProduct && <div className='col-4'>
                    <Select
                        errors={errors.glass_size}
                        label={"Glass Size/Power"}
                        placeholder={"Enter Glass Size/Power"}
                        className={"w-100"}
                        options={(productData?.product_type_id?.label)?.toLowerCase() === reading ? power : glassSizes}
                        value={productData.glass_size}
                        onChange={(e: any) => { handleChange("glass_size", e) }} />
                </div>}
                {!isContactLensProduct && <div className='col-4'>
                    <Select
                        errors={errors.temple_size}
                        label={"Temple Size"}
                        placeholder={"Enter Temple Size"}
                        className={"w-100"}
                        options={templeSizes}
                        value={productData.temple_size}
                        onChange={(e: any) => { handleChange("temple_size", e) }} />
                </div>}
            </div>
            {isContactLensProduct && <div className='row mt-3'>
                <div className='col-4'>
                    <Input errors={errors?.pack_of_quantity} type={'number'} label={"Pack of"} placeholder={"Enter Pack of"} value={productData?.pack_of_quantity} onChange={(e: any) => { handleChange("pack_of_quantity", e.target.value) }} />
                </div>
                <div className='col-4'>
                    <Input label={"Product SRP"} placeholder={"Enter SRP"} errors={errors.product_srp} value={productData.product_srp}
                        onChange={(e: any) => { handleChange("product_srp", e.target.value) }} />
                </div>
                <div className='col-4'>
                    <div className=" px-2">
                        <Input className="form-control px-4 py-3" type="file" id="formFile"
                            ref={fileInputRef}
                            errors={errors.images}
                            onChange={(e: any) => {
                                _handleImageChange(e)
                            }}
                            label={"Upload Images"}
                            accept={"image/png, image/gif, image/jpeg"}
                            multiple={true}
                            placeholder={"formate :- JPG,JPGE,PNG"} />
                    </div>
                </div>
            </div>}
            {!isContactLensProduct && <div className='row mt-3'>
                <div className='col-4'>
                    <Select
                        errors={errors.bridge_size}
                        label={"Bridge Size"}
                        placeholder={"Enter Bridge Size"}
                        className={"w-100"}
                        options={bridgeSizes}
                        value={productData.bridge_size}
                        onChange={(e: any) => { handleChange("bridge_size", e) }} />
                </div>
                <div className='col-4 '>
                    <Select label={"Product Material"} errors={errors.product_materials} options={productMaterial} className={"w-100"} placeholder={"Select Material"} isMulti={true} value={productData.product_materials}
                        onChange={(e: any) => { handleChange("product_materials", e) }} />
                </div>
                <div className='col-4'>
                    <Select errors={errors.features} label={"Product Features"} className={"w-100"} options={constant.PRODUCT_FEATURES} placeholder={"Select Features"} isMulti={true} value={productData.features} onChange={(e: any) => {
                        handleChange("features", e)

                    }} />
                </div>
            </div>}
            {!isContactLensProduct && <div className='row mt-3'>
                <div className='col-4'>
                    <Select label={"Rim Type"} errors={errors.rim_type} className={"w-100"} options={constant.PRODUCT_RIM_TYPE} value={productData.rim_type} placeholder={"Select Rim"}
                        onChange={(e: any) => { handleChange("rim_type", e) }} />
                </div>
                <div className='col-4'>
                    <Select label={"Product Shape"} isMulti={true} errors={errors.shapes} options={productShape} className={"w-100"} placeholder={"Select Shape"} value={productData.shapes}
                        onChange={(e: any) => { handleChange("shapes", e) }} />
                </div>
                <div className='col-4'>
                    <Select label={"Gender"} className={"w-100"} errors={errors.gender} isMulti={true} options={constant.GENDER} placeholder={"Select Gender"} value={productData.gender}
                        onChange={(e: any) => { handleChange("gender", e) }} />
                </div>
            </div>}
            {!isContactLensProduct && <div className='row mt-3'>
                <div className='col-4'>
                    <Input label={"Product SRP"} placeholder={"Enter SRP"} errors={errors.product_srp} value={productData.product_srp}
                        onChange={(e: any) => { handleChange("product_srp", e.target.value) }} />
                </div>
                <div className='col-4'>
                    <Select label={"Warranty"} errors={errors.warranty} className={"w-100"} placeholder={"Select Warranty"} options={constant.PRODUCT_WARRANTY}
                        value={productData.warranty} onChange={(e: any) => { handleChange("warranty", e) }} />
                </div>
                <div className='col-4'>
                    <Select label={"Product Weight"} errors={errors.weight} options={constant.PRODUCT_WEIGHT} className={"w-100"} placeholder={"Enter Weight"}
                        value={productData.weight} onChange={(e: any) => { handleChange("weight", e) }} />
                </div>
            </div>}
            {!isContactLensProduct && <div className='row mt-3'>
                <div className='col-4 '>
                    <label className='inputs-label mb-1 fs-16 fw-600  secondary-color ' htmlFor='true'>Product With Case</label>
                    <form className='d-flex gap-3'>
                        <RadioInput type="radio" className={"form-check-input"} checked={productData.product_with_case === true && true} label={"Yes"} onChange={(e: any) => { setProductData({ ...productData, product_with_case: true }) }} id={'Yes'} />
                        <RadioInput type="radio" className={"form-check-input"} checked={productData.product_with_case === false && true} label={"No"} onChange={(e: any) => { setProductData({ ...productData, product_with_case: false }) }} id={'No'} />
                    </form>
                </div>
                <div className='col-4'>
                    <Select label={"Polarized"} className={"w-100"} errors={errors.isPolarised} placeholder={"Select Polarized"} options={constant.POLARIZED}
                        value={productData.isPolarised} onChange={(e: any) => { handleChange("isPolarised", e) }}
                    />
                </div>
                <div className='col-4'>

                    <div className=" px-2">
                        <Input className="form-control px-4 py-3" type="file" id="formFile"
                            ref={fileInputRef}
                            errors={errors.images}
                            onChange={(e: any) => {
                                _handleImageChange(e)
                            }}
                            label={"Upload Images"}
                            accept={"image/png, image/gif, image/jpeg"}
                            multiple={true}
                            placeholder={"formate :- JPG,JPGE,PNG"} />
                    </div>
                </div>
            </div>}
            {<div className='mt-3'>
                <div className="d-flex row">
                    {previewImage && previewImage.map((image: any, index) => {
                        return (
                            <div className="photo_upload mb-2 m-3 col-2" onClick={() => _handelCurrentCover(index)}>
                                <div className="photo_preview cursor-pointer mb-2">
                                    <div
                                        id="imagePreview"
                                    >
                                        <Image src={`${image && image}`} alt='aaa' className={"img-fluid preview_image"} />

                                    </div>
                                </div>
                                <div className='align-items-center d-flex delete-icon justify-content-center'
                                    onClick={() => { handleImageRemove(image, index) }}
                                >
                                    <Image src={CloseIcon} />
                                </div>
                                {productData.cover_index === index && <div className='align-items-center bottom-0 position-absolute text-center w-100 cover-image'>
                                    <p className='cover-text fs-12 fw-500 my-3'>Cover Image</p>
                                </div>}
                            </div>
                        )
                    })
                    }
                </div>
            </div>}
            <div className='mt-3'>
                <Input label={"Product Description"} value={productData.description}
                    onChange={(e: any) => { handleChange("description", e.target.value) }}
                    className='text-capitalize'
                    placeholder='Enter Product Description' errors={errors.description} />

            </div>
            <div className='justify-content-center d-flex mt-4'>
                {productEditID ? (
                    <>
                        {!isDuplicateValue ? <>
                            <Button name={"Delete"} className={"w-25 m-4 Delete-Button"} onClick={() => { setHideDeletePopup(true) }} />
                            <Button name={"Save Changes"} className={"w-25 m-4 "} onClick={() => { handleSubmit() }} />
                        </> :
                            <Button name={"Duplicate"} className={"w-25 m-4 "} onClick={() => { handleSubmit() }} />
                        }
                    </>
                ) : (
                    <Button name={"Continue"} className={"w-25 m-4"} onClick={() => {
                        handleSubmit()
                    }} />
                )}
            </div>
            <div>
                <ConfirmationModal message={"Product"} show={hideDeletePopup} onHide={() => { setHideDeletePopup(false) }} handleConfirmItem={() => { setDeleteClick(true) }} />
            </div>
        </div>
    )

}
export default AddProduct