import React, { useContext, useEffect, useState } from "react";
import Wrapper from "../../../../components/common/Wrapper/Wrapper";
import organisms from "../../../../components/organisms";
import service from "../../../../api/services";
import { getDropdown, handleReportDownload } from "../../../../constants/utils";
import { HeaderHeading } from "../../../../Context";
import { useToasts } from "react-toast-notifications";
import { SettlementProps } from "./interface";
const { SettlementNotes } = organisms;

const Index = () => {
  const { setHeader }: any = useContext(HeaderHeading);
  const { addToast } = useToasts();
  const [storeData, setStoreData] = useState<
    { label: string; value: string }[]
  >([]);
  const [reportData, setReportData] = useState<SettlementProps>({
    store_ids: { label: "Select Stores", value: "" },
    created_from: new Date(),
    created_to: new Date(),
    report_format: 0,
    report_type: 1,
  });
  const getStoreList = async () => {
    try {
      let storeResponse = await service.inventory.storeView();
      if (storeResponse.status === 200) {
        setStoreData(
          getDropdown(storeResponse?.data?.data, "store_location", "store_name")
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleChange = (name: string, value: string | {label:string ,value:string}) => {
    setReportData({ ...reportData, [name]: value });
  };
  const submitIndividualInvoices = async () => {
    const payload = {
      store_id: reportData?.store_ids?.value,
      from_date: reportData?.created_from,
      to_date: reportData?.created_to,
    };
    try {
      const response = await service.reportService?.settlementNote(payload);
      if (response.status === 200) {
        let fullPath = response?.data?.data?.path;
        let cleanPAth = fullPath.replace(/api\/\.\//, "");
        let fileName = cleanPAth.substring(cleanPAth.lastIndexOf("/") + 1);

        setTimeout(() => {
          fetch(`${cleanPAth}`).then((response) => {
            response.blob().then((blob) => {
              // Creating new object of PDF file
              const fileURL = window.URL.createObjectURL(blob);

              // Setting various property values
              let alink = document.createElement("a");
              alink.href = fileURL;
              alink.download = `${fileName}`;
              alink.click();
            });
          });
        }, 2000);
      }
    } catch (error: any) {
      console.log("error", error);
      addToast(error?.response?.data?.message, {
        appearance: "error",
        autoDismiss: true,
        autoDismissTimeout: 3000,
      });
    }
  };
  useEffect(() => {
    setHeader("Movement - Settlement Note");
    getStoreList();
  }, []);

  return (
    <Wrapper>
      <SettlementNotes
        handleChange={(name: string, value:string | {label:string ,value:string}) => handleChange(name, value)}
        reportData={reportData}
        storeData={storeData}
        submitIndividualInvoices={() => submitIndividualInvoices()}
      />
    </Wrapper>
  );
};

export default Index;
