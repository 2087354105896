
import React, { useState, useEffect,useContext } from 'react';
import service from '../../../../api/services';
import Wrapper from '../../../../components/common/Wrapper/Wrapper';
import organisms from '../../../../components/organisms';
import { Default_Page_Limit } from '../../../../constants/defaultValues'
import { getDropdown, getParams } from '../../../../constants/utils';
import { HeaderHeading } from '../../../../Context'
import { useSearchParams } from 'react-router-dom';

const { InventorySearch } = organisms
const Index = () => {
  const [searchParams] = useSearchParams();
  const [typeValue, setTypeValue] = useState<any>(getParams('search-type'))
  const [storeData, setStoreData] = useState<any>();
  const [brandData, setBrandData] = useState<any>();
  const [limit, setLimit] = useState(Default_Page_Limit);
  const [listingData, setListingData] = useState<any>()
  const [isOpen, setIsOpen] = useState(true);
  const [noDataText, setNoDataText]=useState<Boolean>(false)
  const { setHeader, setSidebar }: any = useContext(HeaderHeading);
  const [isContactLensProduct,setIsContactLensProduct]= useState<boolean>(false)
  const [range, setRange] = React.useState({
    minPrice:null,
    maxPrice:null
  });
  const [searchdata, setSearchdata] = useState({
    brand: [],
    store: [],
    product_id: "",
    modal_number: "",
    barcode: "",
    price: '',
    created_from:"",
    created_to:"",
  })
  useEffect(() => {
    setStoreData([])
    setListingData([])
    setLimit(Default_Page_Limit)
    setSearchdata({
      brand: [],
      store: [],
      product_id: "",
      modal_number: "",
      barcode: "",
      price: '',
      created_from:"",
      created_to:"",
    })
    setTypeValue(getParams('search-type'))
}, [searchParams])
  const handleSubmit = async () => {
    const brandIds = searchdata?.brand?.map((item: any) => item.value)
    const storeIds = searchdata?.store?.map((item: any) => item.value)
    const payload = {
      brand: brandIds,
      store: storeIds,
      product_id: searchdata.product_id.replace(/ +/g, ""),
      modal_number: searchdata.modal_number.replace(/ +/g, ""),
      barcode: searchdata.barcode,
      price: {
        "minPrice": range.minPrice,
        "maxPrice": range.maxPrice
      },
      created_from: searchdata?.created_from,
      created_to: searchdata?.created_to,
      is_contact_lens: isContactLensProduct,
      brand_category:1
    }
    try {
      const response = await service.inventory.searchView(payload)
      if (response.status == 200) {
        setListingData(response.data.data)
        setNoDataText(true)
        if(response?.data?.data.length>0){
          setIsOpen(false)
        }
      }
    } catch (error) {
      console.log(error)
    }
  }
  const getInvoiceData = async () => {
    try {
      let storeResponse = await service.inventory.storeView({brand_category: typeValue})
      let brandResponse = await service.inventory.brandView({brand_category: typeValue})

      if (storeResponse.status === 200 || brandResponse.status === 200) {
        setStoreData(getDropdown(storeResponse?.data?.data, 'store_location', 'store_name'))
        setBrandData(getDropdown(brandResponse?.data?.data, 'brand_name'))
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handlePriceRange = (event: any, newValue: any) => {
    setRange(newValue);
  }

  const handleInputPrice = (e: any) => {
    e.preventDefault();
    setRange(previousState => ({
        ...previousState, [e.target.name]: e.target.value
    }));
  }

  const handleLensTypeChange = (value:boolean)=>{
    setIsContactLensProduct(value)
    setSearchdata({
      brand: [],
      store: [],
      product_id: "",
      modal_number: "",
      barcode: "",
      price: '',
      created_from:"",
      created_to:"",
    })
  }

  useEffect(() => {
    setHeader("Inventory - Search")
    getInvoiceData()
    handleSubmit()
  }, [typeValue,isContactLensProduct])

  return (
    <Wrapper>
      <InventorySearch
        storeData={storeData}
        brandData={brandData}
        searchdata={searchdata}
        setSearchdata={setSearchdata}
        handleSubmit={handleSubmit}
        listingData={listingData}
        range={range}
        handlePriceRange={handlePriceRange}
        handleInputPrice={handleInputPrice}
        setLimitPerPage={setLimit}
        limit={limit}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        noDataText={noDataText}
        isContactLensProduct={isContactLensProduct}
        handleLensTypeChange={(value:boolean)=>handleLensTypeChange(value)}
      />
    </Wrapper>
  )
}
export default Index