import React from 'react'
import { Navigate } from 'react-router'
import privatePages from '../pages/App'
import publicPages from '../pages/Auth'
const { Dashboard, Stores, ApproveProduct, PendingProduct, ReworkProduct, BulkEditProduct, Movement, MovementDetail,
    MovementPending, MovementInprocess, MovementDelivered, MovementCancelled, AddProduct, AddStores, Brand, ProductType,
    Material, Color, Shape, AddBrands, SubAdmin, AddSubAdmin, Supplier, AddSupplier, Permissions, Role, BarcodeStatus,
    PendingBarcodeStatus, PriceUpdatedBarcode, MoveOutBarcode, LostBarcodeHistory, LostBarcode, AddRole, PendingInventory, ApproveInventory, AddInventory, StoreAllocation,
    BrandAllocation, EditBrandAllocation, AddBrandAllocation, InventoryQuantity, InventorySearch, BulkUpload,
    SubAccount, GeneralAccount, AddVoucher, AllEntries, Procurement, LoyaltyPoints, LoyaltyPointsItems, AddLoyalty, Warranty, InventoryAudit,
    PurchasedReport, StoreSalesReport, InventoryMovementReport, InventoryReport, GSTOutputReport, CustomerReport, StoreReport, BarcodeAuditTrail, InventoryAdditionReport, InvoicingReport,
    TransactionReport, GeneralAccountReport, StoreBalanceReport, TrailBalanceReport, OwnershipTransferReport,
    LoyaltyList, SizeList, AddSize, BridgeSizeList, AddBridgeSize, TempleSizeList, AddTempleSize, StoreSalesReturn, InvoicingReturn, FeedbackForms,
    RetailerForms, LocationGroup, LocationMatrix, BulkFilterProduct, BulkApprove, SettlementNotes, SettlementHistory, PowerList,
    AddPowerSize } = privatePages

const { Login, Registration } = publicPages

const privateRoutes = [

    { path: "/", exact: true, component: <Navigate to="/dashboard" />, },
    { path: '/dashboard', component: <Dashboard /> },

    // ===== Data Component ======
    { path: '/power/list', component: <PowerList /> },
    { path: '/power/add', component: <AddPowerSize /> },
    { path: '/power/edit/:id', component: <AddPowerSize /> },
    
    { path: '/size/list', component: <SizeList /> },
    { path: '/size/add', component: <AddSize /> },
    { path: '/size/edit/:id', component: <AddSize /> },
    { path: '/bridge-size/list', component: <BridgeSizeList /> },
    { path: '/bridge-size/add', component: <AddBridgeSize /> },
    { path: '/bridge-size/edit/:id', component: <AddBridgeSize /> },
    { path: '/temple-size/list', component: <TempleSizeList /> },
    { path: '/temple-size/add', component: <AddTempleSize /> },
    { path: '/temple-size/edit/:id', component: <AddTempleSize /> },
    
 

    { path: '/sub-admin', component: <SubAdmin /> },
    { path: '/sub-admin/add', component: <AddSubAdmin /> },
    { path: '/sub-admin/edit/:id', component: <AddSubAdmin /> },

    { path: '/supplier', component: <Supplier /> },
    { path: '/supplier/add', component: <AddSupplier /> },
    { path: '/supplier/edit/:id', component: <AddSupplier /> },

    { path: '/product/brand/add', component: <AddBrands /> },
    { path: '/product/brand/edit/:id', component: <AddBrands /> },

    { path: '/product/brand', component: <Brand /> },
    { path: '/product/type', component: <ProductType /> },
    { path: '/product/material', component: <Material /> },
    { path: '/product/color', component: <Color /> },
    { path: '/product/shape', component: <Shape /> },

    // Product
    { path: '/products/approved', component: <ApproveProduct /> },
    { path: '/recognize-products', component: <ApproveProduct /> },
    { path: '/products/pending', component: <PendingProduct /> },
    { path: '/products/rework', component: <ReworkProduct /> },
    { path: '/products/bulk-edit', component: <BulkEditProduct /> },
    { path: '/products/add/', component: <AddProduct /> },
    { path: '/products/edit/:id', component: <AddProduct /> },
    { path: '/products/bulk-upload', component: <BulkUpload /> },
    { path: '/products/bulk-filter', component: <BulkFilterProduct /> },
    { path: '/products/bulk-approve', component: <BulkApprove /> },

    // Store
    { path: '/stores', component: <Stores /> },
    { path: '/stores/add', component: <AddStores /> },
    { path: '/stores/edit/:id', component: <AddStores /> },

    { path: '/store/inventory-audit', component: <InventoryAudit /> },
    { path: '/store/procurement', component: <Procurement /> },

    { path: '/store/brand/allocation-store', component: <StoreAllocation /> },
    { path: '/store/brand/allocation/add', component: <BrandAllocation /> },
    { path: '/store/brand/allocation/edit/:id', component: <EditBrandAllocation /> },
    { path: '/store/brand/add/:id', component: <AddBrandAllocation /> },

    // Inventory
    { path: '/inventory/pending/list', component: <PendingInventory /> },
    { path: '/inventory/approve/list', component: <ApproveInventory /> },
    { path: '/inventory/add/', component: <AddInventory /> },
    { path: '/inventory/edit/:id', component: <AddInventory /> },
    { path: '/inventory/search', component: <InventorySearch /> },
    { path: '/inventory/quantity', component: <InventoryQuantity /> },

    { path: '/inventory/barcode-status/approved', component: <BarcodeStatus /> },
    { path: '/inventory/barcode-status/pending', component: <PendingBarcodeStatus /> },
    { path: '/inventory/barcode-status/price-updated', component: <PriceUpdatedBarcode /> },
    { path: '/inventory/barcode-status/move-out', component: <MoveOutBarcode /> },
    { path: '/inventory/lost-barcode-history', component: <LostBarcodeHistory /> },
    { path: '/inventory/lost-barcode', component: <LostBarcode /> },


    // Movement
    { path: '/movement/list', component: <Movement /> },
    { path: '/movementdetails/edit/:id', component: < MovementDetail /> },
    { path: '/movement/pending', component: <MovementPending /> },
    { path: '/movement/in-process', component: <MovementInprocess /> },
    { path: '/movement/delivered', component: <MovementDelivered /> },
    { path: '/movement/cancelled', component: <MovementCancelled /> },
    { path: '/movement/settlement-note', component: <SettlementNotes /> },
    { path: '/movement/settlement-history', component: <SettlementHistory /> },

    // Warrantee
    { path: '/warranty', component: <Warranty /> },

    // Accounts
    { path: '/accounts/sub-account', component: <SubAccount /> },
    { path: '/accounts/general-account', component: <GeneralAccount /> },
    { path: '/accounts/all-entries', component: <AllEntries /> },
    { path: '/accounts/add-voucher', component: <AddVoucher /> },

    { path: '/accounts/general-report', component: <GeneralAccountReport /> },
    { path: '/accounts/transaction-report', component: <TransactionReport /> },
    { path: '/accounts/store-balance-report', component: <StoreBalanceReport /> },
    { path: '/accounts/trail-balance-report', component: <TrailBalanceReport /> },

    // Reports
    { path: '/report/purchased', component: <PurchasedReport /> },
    { path: '/report/store-sales', component: <StoreSalesReport /> },
    { path: '/report/store-sales-return', component: <StoreSalesReturn /> },
    { path: '/report/inventory-movement', component: <InventoryMovementReport /> },
    { path: '/report/inventory', component: <InventoryReport /> },
    { path: '/report/gst-output', component: <GSTOutputReport /> },
    { path: '/report/customer', component: <CustomerReport /> },
    { path: '/report/store', component: <StoreReport /> },
    { path: '/report/barcode-audit-trail', component: <BarcodeAuditTrail /> },
    { path: '/report/inventory-addition', component: <InventoryAdditionReport /> },
    { path: '/report/invoicing', component: <InvoicingReport /> },
    { path: '/report/invoicing-return', component: <InvoicingReturn /> },
    { path: '/report/ownership-transfer-report', component: <OwnershipTransferReport /> },

    { path: '/report/loyalty-points', component: <LoyaltyPoints /> },
    { path: '/report/loyalty-points/Items/:id', component: <LoyaltyPointsItems /> },


    // Settings
    { path: '/permissions', component: <Permissions /> },

    { path: '/setting/role', component: <Role /> },
    { path: '/setting/role/add', component: <AddRole /> },
    { path: '/setting/role/edit/:id', component: <AddRole /> },

    { path: '/setting/loyalty/edit/:id', component: <AddLoyalty /> },
    { path: '/setting/loyalty/list', component: <LoyaltyList /> },
    { path: '/setting/location-group', component: <LocationGroup /> },
    { path: '/setting/location-matrix', component: <LocationMatrix /> },

    // Website Forms
    { path: 'feedback', component: <FeedbackForms /> },
    { path: 'retailer', component: <RetailerForms /> },


]

const PublicRoutes = [
    { path: '/login', component: <Login /> },
    { path: '/registration', component: <Registration /> },
]
export { privateRoutes, PublicRoutes }