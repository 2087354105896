import { useEffect, useState, useContext } from "react"
import service from "../../../../api/services"
import Wrapper from "../../../../components/common/Wrapper/Wrapper"
import organisms from '../../../../components/organisms'
import { Default_Page_Limit, Default_Page_Number } from "../../../../constants/defaultValues"
import { HeaderHeading } from '../../../../Context'
import { getDropdown, getParams } from "../../../../constants/utils"
import constant from "../../../../constants/constant"
import { useSearchParams } from "react-router-dom"
const { BarcodeStatus } = organisms
const Index = () => {
    const [searchParams] = useSearchParams();
    const [typeValue, setTypeValue] = useState<any>(getParams('status-approve-type') == constant.BRAND_CATEGORY[0]?.value)
    const [barcodeStatusList, setBarcodeStatus] = useState<string>()
    const [initialLoading, setInitialLoading] = useState<boolean>(false)
    const [search, setSearch] = useState('');
    const [pageCount, setPageCount] = useState<number>(Default_Page_Number);
    const [limit, setLimit] = useState(Default_Page_Limit);
    const { setHeader, setSidebar }: any = useContext(HeaderHeading);
    const [allBarcode, setAllBarcode] = useState<any>([]);
    const [storeData, setStoreData] = useState([]);
    const [storeId, setStoreId] = useState<any>([])
    const [isContactLensProduct,setIsContactLensProduct]=useState<boolean>(false)


    useEffect(() => {
        let tempValue: any = getParams('status-approve-type')
        setTypeValue(tempValue == constant.BRAND_CATEGORY[0]?.value)
        setAllBarcode([])
        setPageCount(Default_Page_Number)
        setLimit(Default_Page_Limit)
        setSearch('')
      }, [searchParams])

    const getStoreList = async () => {
        try {
            let storeResponse = await service.inventory.storeView()
            if (storeResponse.status === 200) {
                setStoreData(getDropdown(storeResponse?.data?.data, 'store_location', 'store_name'))
            }
        } catch (error) {
            console.log(error)
        }
    }

    const getBarcodeStatus = async () => {
        const storeIds = storeId?.map((item: any) => item.value)
        const data = {
            search: search,
            page: pageCount,
            limit: limit,
            barcode_status: constant?.BARCODE_STATUS?.APPROVED,
            store_ids: storeIds,
            is_recognised: typeValue,
            is_contact_lens:isContactLensProduct
        }

        try {
            const response = await service.barcodeStatus.barcodeStatusService(data);
            setBarcodeStatus(response.data)
            const combinedArray = [...allBarcode, ...response.data.data];
            const uniqueIds = new Set<number>();

            // Filter the combined array to include only unique entries based on ID
            const uniqueArray = combinedArray.filter((item) => {
                if (!uniqueIds.has(item.id)) {
                    uniqueIds.add(item.id);
                    return true;
                }
                return false;
            });
            // Update the state with the unique array
            setAllBarcode(uniqueArray);
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        setHeader("Inventory - Approve Barcode")
        setSidebar(false)
        getBarcodeStatus()
    }, [pageCount,typeValue])

    useEffect(() => {
        setPageCount(1)
        if (pageCount == 1 && initialLoading) {
            getBarcodeStatus()
        }
        setInitialLoading(true)
    }, [limit, search, storeId,isContactLensProduct])

    useEffect(() => {
        getStoreList()
    }, [])

    return (
        <Wrapper>
            <BarcodeStatus
                barcodeStatusList={barcodeStatusList}
                setSearch={setSearch}
                setLimitPerPage={setLimit}
                setCurrentPage={setPageCount}
                getBarcodeStatus={getBarcodeStatus}
                currentPage={pageCount}
                limit={limit}
                allBarcode={allBarcode}
                storeData={storeData}
                handleChange={(value:any)=>setStoreId(value)}
                setIsContactLensProduct={setIsContactLensProduct}
                isContactLensProduct={isContactLensProduct}
            />
        </Wrapper>
    )
}
export default Index