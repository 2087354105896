const productData = {
    // =====brand====
    brand: () => 'admin/brand',
    brandById: (id: string) => `admin/brand/${id}`,
    brandSearch: () => 'store/brand/search',


    // =====Product Type====
    productType: () => 'admin/product-type',
    productTypeById: (id: string) => `admin/product-type/${id}`,

    // =====Product Material====
    productMaterial: () => 'admin/product-material',
    productMaterialById: (id: string) => `admin/product-material/${id}`,

    // =====Product Shape====
    productShape: () => 'admin/product-shape',
    productShapeById: (id: string) => `admin/product-shape/${id}`,

    // =====Product Color====
    productColor: () => 'admin/color-code',
    productColorById: (id: string) => `admin/color-code/${id}`,
    
    
    sizeDetails: () => 'admin/size-details',
}
export default productData