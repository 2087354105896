import React, { useMemo, useState } from 'react'
import { BsArrowDownShort, BsArrowUpShort, BsXCircle } from 'react-icons/bs';
import { FiEdit } from "react-icons/fi";
import { RiDeleteBin6Line } from 'react-icons/ri';
import { findConstantLabel, setMaxNumber } from '../../../constants/utils'
import { useParams } from 'react-router-dom';
import atoms from '../../atoms';
import './index.scss'
import { BrandAllocation, BrandData, ProductType } from './interface';
import constant from '../../../constants/constant';
import StoreBrandStatusRadio from './RadioButton';
import { ImCross, ImCheckmark } from "react-icons/im";

const { Button, Input, Search } = atoms
const Index = ({ setBrandList, brandList, selectedBrand, setSelectedBrand, step, search, setSearch, setButtonClick, removeBrand }: BrandAllocation | any) => {
    const params = useParams()
    const editStoreId = params?.id || ''
    const [sortAsc, setSortAsc] = useState(true);
    const [sortTypeAsc, setSortTypeAsc] = useState(true);
    const [isEdit, setIsEdit] = useState(false);
    const [editIndex, setEditIndex] = useState<number>();

    const sortDataByName = () => {
        const sortedData = [...filterData].sort((a, b) => {
            if (sortAsc) {
                return a?.brand_name?.localeCompare(b?.brand_name);
            } else {
                return b?.brand_name?.localeCompare(a?.brand_name);
            }
        });
        setBrandList(sortedData);
        setSortAsc(!sortAsc)
    };
    const sortProductTypesByName = () => {
        const sortedData = [...filterData].sort((a, b) => {
            if (sortTypeAsc) {
                return a.brand_name.localeCompare(b.brand_name);
            } else {
                return b.brand_name.localeCompare(a.brand_name);
            }
        }).map((obj) => {
            return {
                ...obj,
                product_types: [...obj.product_types].sort((a, b) => {
                    if (sortTypeAsc) {
                        return a.product_type.name.localeCompare(b.product_type.name);
                    } else {
                        return b.product_type.name.localeCompare(a.product_type.name);
                    }
                }),
            };

        });

        setBrandList(sortedData);
        setSortTypeAsc(!sortTypeAsc);
    };

    const filterData = useMemo(() => {
        return brandList?.filter((brand: BrandData | any) => {
            return (
                brand?.brand_name.toLowerCase().includes(search?.toLowerCase()))
        })
    }, [brandList, search])

    const handleSelectToggle = async (items: BrandData) => {
        const updatedItems = {
            ...items, product_types: items.product_types.map((type) => ({ ...type, isTypeSelected: true }))
        };
        if (Array.isArray(selectedBrand) && selectedBrand?.some((item: BrandData) => item.id === items.id)) {
            setSelectedBrand(selectedBrand && selectedBrand?.filter((item: BrandData) => item.id !== updatedItems.id));
        } else {
            setSelectedBrand([...selectedBrand, updatedItems]);
        }
    };

    const checkProductTypeCondition = (data: ProductType[], isTypeSelected: boolean): boolean => {
        let arr: boolean[] = []
        data?.some((item: any) => { item.isTypeSelected && arr.push(item.isTypeSelected) })
        return data.length > 1 && (isTypeSelected ? arr.length > 1 : true)
    }
    const handleSelectBrandType = async (isTypeSelected: boolean, id: string | undefined, productTypeId: string, brand_id: string) => {

        !isEdit ? setSelectedBrand((prevItems: BrandData[]) =>
            prevItems?.map((item: any) => ({
                ...item,
                product_types: item?.product_types?.map((service: any) => {
                    if (brand_id === item?.id && ((id && service.product_type_id === id) || (!id && service.product_type.id === productTypeId))) {
                        return {
                            ...service,
                            isTypeSelected: checkProductTypeCondition(item.product_types, isTypeSelected)
                                ? !isTypeSelected
                                : isTypeSelected,
                        };
                    }
                    return service;
                }),
            }))
        ) :
            setSelectedBrand((prevItems: BrandData) => ({
                ...prevItems,
                product_types: prevItems.product_types.map((service) => {
                    if ((id && service.product_type_id === id) || (!id && service.product_type.id === productTypeId)) {
                        return {
                            ...service,
                            isTypeSelected: checkProductTypeCondition(prevItems.product_types, isTypeSelected)
                                ? !isTypeSelected
                                : isTypeSelected,
                        };
                    }
                    return service;
                }),
            }));
    };


    const handleStatusChange = (item: BrandData, status: number) => {
        const updatedBrands = selectedBrand.map((brand: BrandData) =>
            brand.id === item.id ? { ...brand, brand_status: status } : brand
        );
        setSelectedBrand(updatedBrands);
    };

    const handleAllChecked = (e: boolean) => {
        let brandData: any = []
        if (e) {
            brandData = filterData
        }
        setSelectedBrand(brandData);
    };

    const handleCalculation = (type: string, brandIndex: number, index: number, value: string) => {
        filterData[brandIndex].product_types[index][type] = value
    }

    const handleBrandStatusChange = ({ id, brandIndex, status }: { id: string, brandIndex: number, status: number }) => {
        let temp = [...brandList];
        let index: number = brandList.findIndex((item: { id: string }) => item.id === id)
        temp[index].brand_status = status
        setBrandList(temp)
    }

    const handleRemoveBrand = (index: number) => {
        let tempSelectedBrand = [...brandList]
        tempSelectedBrand?.splice(index, 1)
        setBrandList(tempSelectedBrand)
        if (editStoreId) {
            setButtonClick(true)
        }
    }

    const enableEdit = (status: boolean, index: number) => {
        setIsEdit(status)
        setEditIndex(index)
        if (!status) {
            setButtonClick(true)
        } else {
            setSelectedBrand(filterData[index])
        }
    }

    const checkCondition = (data: any[], value: string): boolean => {
        return data?.some((item) => { if ((item?.product_type?.name || item?.product_type_name) === value) return item?.isTypeSelected });
    };

    const isBrandProductTypeSelected = (value: boolean) => {
        return (
            <>
                {value ? (
                    <i className="bi bi-check-circle-fill text-success"><ImCheckmark /></i>
                ) : (
                    <i className="bi bi-x-circle-fill text-danger"><ImCross /></i>
                )}
            </>
        );
    };

    return (
        <div>
            {step === 0 && <div className="row">
                <div className='col-3'></div>
                <div className="col-6"><Search className={"w-100"} searchCallBack={(e: string) => { setSearch(e) }} /></div>
                <div className='col-3'></div>
            </div>}
            <div className=''>
                {filterData && filterData?.length > 0 ? <div className={`custom_table table-responsive ${step !== 2 ? "mt-5" : ''} custom-height hide-scroll`} >
                    <table className='mb-5 w-100 position-relative '>
                        <thead className='table-thead background-blue color-black position-sticky sticky-top '>
                            {/* <thead className='table-thead background-blue color-black' style={{position:'sticky'}}> */}
                            <th className='border-right p-1'>
                                <div className='d-flex' style={{ width: "190px" }}>
                                    {step === 0 && <div className='mt-1 p-1 img-cont mb-2  d-flex align-items-center'>
                                        <input type='checkbox' className=" cursor-pointer" checked={selectedBrand?.length === filterData?.length} onChange={(e: any) => handleAllChecked(e.target.checked)} />
                                    </div>}
                                    <div className=' brandName p-2  d-flex align-items-center'>
                                        <span className="fw-500"> Brand Name{sortAsc ? <BsArrowDownShort onClick={sortDataByName} className="cursor-pointer" /> :
                                            <BsArrowUpShort className="cursor-pointer" onClick={sortDataByName} />}</span>
                                    </div>
                                </div>
                            </th>
                            <th className='text-center'>Status</th>
                            <th className='text-center'>   <span className="fw-500">Product Type{sortTypeAsc ? <BsArrowDownShort onClick={sortProductTypesByName} className="cursor-pointer" /> :
                                <BsArrowUpShort className="cursor-pointer" onClick={sortProductTypesByName} />}</span>
                            </th>
                            <th className='text-center'>Brand Margin &#40;%&#41; </th>
                            <th className='text-center'>Brand Landing Cost &#40;%&#41;</th>
                            <th className='text-center'>Brand Discount &#40;%&#41;</th>
                            {(step !== 0) && <th className='text-center'>Action</th>}
                        </thead>

                        <tbody className='mt-3 table-tbody' >
                            {filterData?.map((brand: BrandData, brandIndex: number) => (
                                (brand?.product_types)?.map((product: ProductType, index: number) => {
                                    return (
                                        <tr key={`${brand?.id}_${index}`}
                                        // className={`${index % 2 !== 0 && "background-light-blue"}`} 
                                        >
                                            {index === 0 ? (
                                                <>
                                                    <td rowSpan={brand?.product_types?.length} className='border-right p-1' style={{ backgroundColor: "#fff" }}>
                                                        <div className=' d-flex  '>
                                                            {step === 0 && <div className='mt-1 p-1 img-cont mb-2 d-flex align-items-center'>
                                                                <input type='checkbox' className=" cursor-pointer"
                                                                    checked={Array.isArray(selectedBrand) && selectedBrand?.some((item: any) => item.id === brand.id)}
                                                                    onChange={(e) => handleSelectToggle(brand)} />
                                                            </div>}
                                                            <div className='d-flex align-items-center brandName'>
                                                                <span className="fs-12">{brand?.brand_name}</span>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </>
                                            ) : null}
                                            {index === 0 ? (
                                                <>
                                                    <td rowSpan={brand?.product_types?.length} className='border-right p-1' style={{ backgroundColor: "#fff" }}>
                                                        {(isEdit && (editIndex === brandIndex)) || (editStoreId && (step == 0)) || !editStoreId && (step == 0) ?
                                                         <StoreBrandStatusRadio
                                                            onStatusChange={(rowId, newStatus) => !isEdit ? handleStatusChange(brand, newStatus) : handleBrandStatusChange({ id: brand.id, brandIndex: brandIndex, status: newStatus })}
                                                            rowId={brand.id}
                                                            status={!isEdit ? selectedBrand?.find((item: any) => item.id === brand.id)?.brand_status : brand.brand_status}
                                                            defaultValue={constant.STORE_BRAND_STATUS[0].value}
                                                            disabled={Array.isArray(selectedBrand) && !selectedBrand?.some((item: any) => item.id === brand.id)}
                                                        /> : findConstantLabel(constant.STORE_BRAND_STATUS, brand.brand_status || constant.STORE_BRAND_STATUS[0].value)}
                                                    </td>
                                                </>
                                            ) : null}
                                            <td className='text-center'>
                                                <div className=' d-flex'>
                                                    {((step === 0) || (isEdit && editIndex === brandIndex)) ? <div className='p-1 img-cont d-flex align-items-center'>
                                                        <input type='checkbox' className=" cursor-pointer"
                                                            checked={Array.isArray(selectedBrand) ? selectedBrand?.some((item: any) => (item?.id === brand?.id && checkCondition(item.product_types, product.product_type.name))) : (editIndex === brandIndex) && (selectedBrand?.id === brand?.id && checkCondition(selectedBrand.product_types, product.product_type_name))}
                                                            onChange={(e) => handleSelectBrandType(Array.isArray(selectedBrand) ? selectedBrand?.some((item: any) => checkCondition(item.product_types, product.product_type.name)) : checkCondition(selectedBrand.product_types, product.product_type_name), product?.product_type_id, product?.product_type?.id, brand?.id)}
                                                            disabled={!isEdit && Array.isArray(selectedBrand) && !selectedBrand?.some((item: any) => item.id === brand.id)}
                                                        />
                                                    </div> : isBrandProductTypeSelected(product.isTypeSelected)}
                                                    <div className='d-flex align-items-center brandName'>
                                                        <span className="fs-12">{product?.product_type?.name || product?.product_type_name}</span>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className='text-center'>
                                                {(isEdit && (editIndex === brandIndex)) || (editStoreId && (step == 0)) || !editStoreId && (step == 0) ? <Input type='number' className={"custom-input-height fs-12"} placeholder={"Brand Margin"} defaultValue={product?.brand_margin}
                                                    onChange={(e: any) => e.target.value <= 100 && handleCalculation("brand_margin", brandIndex, index, e.target.value)}
                                                    disabled={Array.isArray(selectedBrand) ? !selectedBrand?.some((item: any) => (item?.id === brand?.id && checkCondition(item.product_types, product.product_type.name))) : !checkCondition(selectedBrand.product_types, product?.product_type_name)}
                                                /> : product?.brand_margin}
                                            </td>
                                            <td>
                                                {/* {(isEdit && (editIndex === brandIndex)) || (editStoreId && (step == 0)) || !editStoreId && (step == 0) ? <Input type='number' className={"custom-input-height fs-12"} placeholder={"Brand Landing Cost"} defaultValue={product?.brand_landing_cost}
                                                onChange={(e: any) =>e.target.value<=100 && handleCalculation("brand_landing_cost", brandIndex, index, e.target.value)} /> : product?.brand_landing_cost} */}
                                                {(isEdit && (editIndex === brandIndex)) || (editStoreId && (step == 0)) || !editStoreId && (step == 0) ? <div className='background-secondary p-2'>{product?.brand_landing_cost}</div> : product?.brand_landing_cost}
                                            </td>
                                            <td className='text-center'>
                                                {(isEdit && (editIndex === brandIndex)) || (editStoreId && (step == 0)) || !editStoreId && (step == 0) ? <Input type='number' className={"custom-input-height fs-12"} placeholder={"Brand Discount"} defaultValue={product?.brand_discount}
                                                    onChange={(e: any) => e.target.value <= 100 && handleCalculation("brand_discount", brandIndex, index, e.target.value)}
                                                    disabled={Array.isArray(selectedBrand) ? !selectedBrand?.some((item: any) => (item?.id === brand?.id && checkCondition(item.product_types, product.product_type.name))) : !checkCondition(selectedBrand.product_types, product?.product_type_name)}
                                                /> : product?.brand_discount
                                                }
                                            </td>
                                            {index === 0 ? (
                                                <>
                                                    {step === 2 && <td rowSpan={brand?.product_types?.length} className='border-left'>
                                                        <BsXCircle className='fs-3 cursor-pointer' onClick={() => handleRemoveBrand(brandIndex)} />
                                                    </td>}
                                                    {editStoreId && (step !== 0) && <td rowSpan={brand?.product_types?.length} className='border-left' style={{ backgroundColor: "#fff" }}>
                                                        <div className='d-flex gap-3'>
                                                            <div className=''>
                                                                <Button className="table-edit-button fs-14 fw-400 "
                                                                    onClick={() => isEdit && (editIndex === brandIndex) ?
                                                                        enableEdit(false, brandIndex) : enableEdit(true, brandIndex)}
                                                                >
                                                                    <div className='d-flex align-items-center'>
                                                                        <FiEdit />
                                                                        <span className='ms-2 mb-0'>{isEdit && (editIndex === brandIndex) ? "Save" : "Edit"}</span>
                                                                    </div>
                                                                </Button>
                                                            </div>
                                                            <div className=''>

                                                                <Button className="custom-delete-button"
                                                                    onClick={() => removeBrand(brand?.id)} >
                                                                    <div className='d-flex align-items-center fs-14 fw-400 ms-3'>
                                                                        <RiDeleteBin6Line />
                                                                        <span className='ms-2 mb-0'>Remove</span>
                                                                    </div>
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </td>}


                                                </>
                                            ) : null}
                                        </tr>
                                    )
                                })
                            ))}
                        </tbody>

                    </table>
                </div >
                    : <div className='justify-content-center d-flex mt-5 '>There are no records to display</div>}
            </div>
        </div>
    )
}

export default Index