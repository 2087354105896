import React from 'react'
import molecules from '../../../../molecules'
import atoms from '../../../../atoms'

const { Input, Button } = atoms
const { TopBar } = molecules

const Index = ({ addSize, updateSize, sizeId, isSubmit, size, setSize }: any) => {
    return (
        <>
            <div className="row">
                <div className="col-6 d-flex my-auto">
                    <TopBar heading={sizeId ? 'Update' : 'Add'}/>
                </div>
            </div>
            <div className="w-50  mt-4">
                <div className="mt-3">
                    <Input label={"Power"}
                        type={'text'}
                        allowArthmetics={true}
                        placeholder={"Enter Power"}
                        value={size.value}
                        onChange={(e: any) => setSize({ ...size, value: e })}
                        errors={(!size.value && isSubmit) && 'This field is required'}
                    />
                </div>

                <div className="mt-5 text-end">
                    <Button
                        onClick={() => sizeId ? updateSize() : addSize() }>Submit</Button>
                </div>
            </div>
        </>
    )
}

export default Index