import React, { useContext, useEffect, useState } from 'react'
import Wrapper from '../../../../components/common/Wrapper/Wrapper'
import service from '../../../../api/services'
import organisms from '../../../../components/organisms'
import { Default_Page_Limit } from '../../../../constants/defaultValues'
import { useToasts } from 'react-toast-notifications'
import { HeaderHeading, isLoaderLoading } from '../../../../Context'
import { ShareData } from '../../../../components/organisms/Movement/SettlementHistory/interface'
const { SettlementHistory } = organisms
const Index = () => {
  const { addToast } = useToasts();
  const { setHeader, setSidebar }: any = useContext(HeaderHeading);
  const { isLoading, setIsLoading } = useContext<any>(isLoaderLoading);
  const [initialLoading, setInitialLoading] = useState<boolean>(false)
  const [settlementHistory, setSettlementHistory] = useState<any>()
  const [search, setSearch] = useState('');
  const [pageCount, setPageCount] = useState<number>(1);
  const [limit, setLimit] = useState(Default_Page_Limit);

  const getSettlementHistory = async () => {
    try {
      const response = await service.reportService?.settlementHistory({ search: search, page: pageCount, limit: limit, });
      setSettlementHistory(response.data)
    } catch (error) {
      console.log(error)
    }
  }

  const handleShare = async ({ id, store_id, from_date, to_date }: ShareData) => {
    const payload = {
      store_id: store_id,
      from_date: from_date,
      to_date: to_date
    }
    try {
      let response = await service.reportService.settlementNoteShare(id, payload);
      if (response.status === 200) {
        addToast(response.data.message, {
          appearance: 'success',
          autoDismiss: true,
          autoDismissTimeout: 3000,
        });
        getSettlementHistory()
      }
    } catch (error: any) {
      addToast(error?.response?.data?.message, {
        appearance: 'success',
        autoDismiss: true,
        autoDismissTimeout: 3000,
      });
    }
  }

  const handleDownload = (url: string) => {
    setIsLoading(true)
    try {
      let fullPath = url;
      let cleanPAth = fullPath.replace(/api\/\.\//, "");
      let fileName = cleanPAth.substring(cleanPAth.lastIndexOf("/") + 1);
      setTimeout(() => {
        fetch(`${cleanPAth}`).then((response) => {
          response.blob().then((blob) => {
            // Creating new object of PDF file
            const fileURL = window.URL.createObjectURL(blob);

            // Setting various property values
            let alink = document.createElement("a");
            alink.href = fileURL;
            alink.download = `${fileName}`;
            alink.click();
          });
        });
        setIsLoading(false)
        addToast("Settlement Note Downloaded Successfully.", {
          appearance: 'success',
          autoDismiss: true,
          autoDismissTimeout: 3000,
        });
      }, 2000);

    } catch (error) {
      setIsLoading(false)
      addToast('Failed to Download', {
        appearance: 'error',
        autoDismiss: true,
        autoDismissTimeout: 3000,
      });
    }
  }

  useEffect(() => {
    setHeader("Movement - Settlement History")
    setSidebar(false)
    getSettlementHistory()
  }, [pageCount])

  useEffect(() => {
    setPageCount(1)
    if (pageCount == 1 && initialLoading) {
      getSettlementHistory()
    }
    setInitialLoading(true)
  }, [limit, search])

  return (
    <Wrapper>
      <SettlementHistory
        limit={limit}
        currentPage={pageCount}
        storeListing={settlementHistory}
        setSearch={setSearch}
        setLimitPerPage={setLimit}
        setCurrentPage={setPageCount}
        handleDownload={(id: string) => handleDownload(id)}
        handleShare={({ id, store_id, from_date, to_date }: ShareData) => handleShare({ id: id, store_id: store_id, from_date, to_date })}
        isLoading={isLoading}
      />
    </Wrapper>
  )
}

export default Index