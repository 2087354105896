import React, { useState } from "react";
import atoms from "../../../atoms";
import moment from "moment";
import constant from "../../../../constants/constant";
import {
    modifiedDataForContactLens,
    numberWithCommas,
} from "../../../../constants/utils";
import molecules from "../../../molecules";
import { RxCrossCircled } from "react-icons/rx";
import { IsShowModifiedTableProps, ProcurementItemsProps } from "./interface";

const { Button, Table } = atoms;
const { TopBar, ConfirmationModal, TableItemStatus } = molecules;
const MovementDetail = ({ detailsListing, cancelButton }: any) => {
    const [deletePopup, setDeletePopup] = useState<boolean>(false);
    const [movementItemId, setMovementItemId] = useState<string>("");

    const columns = [
        {
            name: "Product ID",
            selector: (row: any) => row?.product?.product_id_display,
        },
        {
            name: "Product Type",
            selector: (row: any) => row?.product?.product_type?.name,
        },
        {
            name: "Cover Photo",
            selector: (row: any) => (
                <img
                    src={row?.product?.images[0] && row?.product?.images[0]}
                    className="w-50 rounded object-contain"
                />
            ),
        },
        {
            name: "Brand",
            selector: (row: any) => row?.product?.brand?.brand_name || "-",
        },
        {
            name: "Model No",
            selector: (row: any) => row?.product?.modal_number || "-",
        },
        {
            name: "Brand Color Code",
            selector: (row: any) => row?.product?.brand_color_code || "-",
        },
        {
            name: "Glass Size/Power",
            selector: (row: any) => row?.product?.glass_size || "-",
        },
        {
            name: "SRP",
            selector: (row: any) =>
                `₹${numberWithCommas(
                    parseFloat(row?.product?.product_srp?.toFixed(2))
                )}` || "-",
        },
        {
            name: "Landing Cost",
            selector: (row: any) =>
                `₹${numberWithCommas(parseFloat(row?.landing_cost?.toFixed(2)))}` ||
                "-",
        },
        { name: "Status", selector: (row: any) => row?.status || "-" },
        { name: "Action", selector: (row: any) => row?.action || "-" },
    ];

    const contactLensColumns = [
        {
            name: "Product ID",
            selector: (row: any) => row?.product?.product_id_display,
        },
        {
            name: "Product Type",
            selector: (row: any) => row?.product?.product_type?.name,
        },
        {
            name: "Cover Photo",
            selector: (row: any) => (
                <img
                    src={row?.product?.images[0] && row?.product?.images[0]}
                    className="w-50 rounded object-contain"
                />
            ),
        },
        {
            name: "Brand",
            selector: (row: any) => row?.product?.brand?.brand_name || "-",
        },
        {
            name: "SRP",
            selector: (row: any) =>
                `₹${numberWithCommas(
                    parseFloat(row?.product?.product_srp?.toFixed(2))
                )}` || "-",
        },
        {
            name: "Landing Cost",
            selector: (row: any) =>
                `₹${numberWithCommas(parseFloat(row?.landing_cost?.toFixed(2)))}` ||
                "-",
        },
        { name: "Status", selector: (row: any) => row?.status || "-" },
        { name: "Action", selector: (row: any) => row?.action || "-" },
    ];

    const _handleDeletePopup = (id: string) => {
        setMovementItemId(id);
        setDeletePopup(!deletePopup);
    };

    const tableActionSection = ({
        item,
    }: {
        item: { status: number; id: string };
    }) => {
        return (
            <div style={{ overflow: "unset", textOverflow: "unset" }}>
                {!(
                    item.status === constant.PROCUREMENT_ITEM_STATUS.CANCEL ||
                    item.status === constant.PROCUREMENT_ITEM_STATUS.DELIVERED
                ) && (
                        <Button
                            className="table-edit-button fs-14 fw-400 p-2"
                            onClick={() => _handleDeletePopup(item?.id)}
                        >
                            <div className="d-flex align-items-center">
                                <RxCrossCircled className="fs-14 fw-400" />
                                <span className="ms-2 mb-0">Cancel</span>
                            </div>
                        </Button>
                    )}
            </div>
        );
    };

    const modifiedTableData = ({ data, isContactLens }: IsShowModifiedTableProps) => {
        let filterCondition = (items: ProcurementItemsProps): boolean => items?.product?.is_contact_lens === isContactLens
        return data?.filter(filterCondition)
    }

    const isShowModifiedTable = ({ data, isContactLens }: IsShowModifiedTableProps): boolean => {
        return data && modifiedTableData({ data: data, isContactLens: isContactLens }).length > 0
    }

    // TODO do not delete untill testing is done
    // item.status === constant.PROCUREMENT_ITEM_STATUS.PENDING ? (
    //     <div className="background-light-yellow border-product-radius">
    //         <p className="color-yellow m-1 p-1">{'Pending'}</p>
    //     </div>)
    //     : item.status === constant.PROCUREMENT_ITEM_STATUS.TO_BE_DELIVERED ?
    //         (<div className="background-light-blue border-product-radius">
    //             <p className="color-blue m-1 p-1">{"TO_BE_DELIVERED"}</p>
    //         </div>)
    //         : item.status === constant.PROCUREMENT_ITEM_STATUS.DELIVERED ?
    //             (<div className="background-light-green border-product-radius">
    //                 <p className="color-green m-1 p-1">{"DELIVERED"}</p>
    //             </div>)
    //             : item.status === constant.PROCUREMENT_ITEM_STATUS.CANCEL ?
    //                 (<div className="background-light-red border-product-radius">
    //                     <p className="color-red m-1 p-1">{"CANCEL"}</p>
    //                 </div>) : ''
    return (<>
        <div>
            <div className="col-6">
                <TopBar heading={""} />
            </div>

            <div className="row mt-3">
                <div className="col-4 fs-20 fw-800 secondary-color">
                    Movement ID{" "}
                    <p className="col-4 mt-2 fs-20 fw-400 secondary-color">
                        {detailsListing?.procurement_display_id}
                    </p>
                </div>
                <div className="col-4 fs-20 fw-800 secondary-color">
                    Store Name{" "}
                    <p className="col-4 col-md-12 mt-2 fs-20 fw-400 secondary-color">
                        {detailsListing?.store?.store_location
                            ? detailsListing?.store?.store_location
                            : detailsListing?.store?.store_name}
                    </p>
                </div>
                <div className="col-4 fs-20 fw-800 secondary-color">
                    {" "}
                    Date{" "}
                    <p className="col-4 mt-2 fs-20 fw-400 secondary-color">
                        {moment(detailsListing?.updatedAt).format("DD/MM/YYYY")}
                    </p>
                </div>
            </div>
            <hr className="my-3" style={{ border: "1px dashed #222" }} />

            {isShowModifiedTable({ data: detailsListing?.procurement_items, isContactLens: false }) && <div>
                <Table
                    AdditionalClassName="add-invertory-table"
                    columns={columns}
                    data={modifiedTableData({ data: detailsListing?.procurement_items, isContactLens: false })?.map((item: any) => {
                        return {
                            ...item,
                            status: <TableItemStatus itemStatus={item.status} />,
                            action: tableActionSection({ item: item }),
                        };
                    })}
                />
            </div>}

            <div>
                {isShowModifiedTable({ data: detailsListing?.procurement_items, isContactLens: true }) && <Table
                    AdditionalClassName="add-invertory-table"
                    columns={contactLensColumns}
                    data={modifiedTableData({ data: detailsListing?.procurement_items, isContactLens: true })?.map((item: any) => {
                        return {
                            ...item,
                            status: <TableItemStatus itemStatus={item.status} />,
                            action: tableActionSection({ item: item }),
                        };
                    })}
                />}
            </div>

            {deletePopup && (
                <ConfirmationModal
                    show={deletePopup}
                    handleConfirmItem={() => {
                        cancelButton(movementItemId);
                        setDeletePopup(!deletePopup);
                    }}
                    onHide={() => setDeletePopup(!deletePopup)}
                    message={"cancel this movement"}
                />
            )}
        </div>
    </>
    )
}
export default MovementDetail 
