import { useCallback, useEffect, useRef, useState } from 'react'
import service from '../../../../api/services'
import constant from '../../../../constants/constant'
import { Tooltip } from 'react-tooltip'
import { IBarcodeStatusData } from '../../../../pages/App/Inventory/BarcodeStatus/interface'
import { modifiedDataForContactLens, numberWithCommas } from "../../../../constants/utils"
import PlaceHolderImage from '../../../assets/Images/placeholderimg.png'
import moment from 'moment'
import atoms from '../../../atoms'
import molecules from '../../../molecules'
const { Search, Button, Table, Pagination, CustomImages, Select } = atoms
const { Barcode, ToggleBarcodePrice, ContactLensContainer } = molecules
const pendingIndex = ({ setSearch, typeValue, pendingStatusList, setLimitPerPage, setCurrentPage, getPendingStatus, currentPage, limit, allBarcode, storeData, handleChange, setIsContactLensProduct,
    isContactLensProduct }: any) => {
    const [selectedItems, setSelectedItems] = useState<string[]>([]);
    const [downloadBarcode, setDownloadBarcode] = useState<any>([]);
    const [togglePrice, setTogglePrice] = useState<boolean>(false)
    const inputRef: any = useRef()
    const handleSelectToggle = (row: any) => {
        if (selectedItems.includes(row.id)) {
            setSelectedItems(selectedItems.filter((item) => item !== row.id));
        } else {
            setSelectedItems([...selectedItems, row.id]);
        }
    };

    const handleAllChecked = (e: any) => {
        let temp: any = [];
        temp = pendingStatusList?.data.map((item: any) => item.id);
        const uniqueArray = Array.from(new Set([...selectedItems, ...temp]))
        if (e === true) {
            setSelectedItems(uniqueArray);
        } else {
            setSelectedItems(selectedItems.filter(value => !temp.includes(value)));
        }
    };

    const columns = [
        { name: <input type={"checkbox"} checked={pendingStatusList?.data.every((value: any) => selectedItems.includes(value.id))} onChange={(e: any) => handleAllChecked(e.target.checked)} />, selector: (row: IBarcodeStatusData) => (<input type="checkbox" checked={selectedItems.includes(row.id)} onClick={() => handleSelectToggle(row)} />), center: true },
        { name: 'Barcode', selector: (row: IBarcodeStatusData) => `${row.bar_code}`, sortable: true, },
        { name: 'Date', selector: (row: any) => row?.createdAt ? moment(row?.createdAt).format('DD/MM/YYYY') : "-", },
        { name: 'Brand', selector: (row: IBarcodeStatusData) => row.invoice_product_detail?.product?.brand?.brand_name },
        { name: 'Store Name', selector: (row: IBarcodeStatusData) => row?.store?.store_name || "" },
        { name: 'Cover Photo', cell: (row: IBarcodeStatusData) => <CustomImages src={row.invoice_product_detail?.product?.images?.length > 0 && row.invoice_product_detail?.product?.images[0]} />, center: true },
        { name: 'Product Type', selector: (row: IBarcodeStatusData) => row.invoice_product_detail?.product?.product_type?.name },
        { name: 'Product ID', selector: (row: IBarcodeStatusData) => `${row.invoice_product_detail?.product?.product_id_display}`, },
        { name: 'Model No', selector: (row: IBarcodeStatusData) => `${row.invoice_product_detail?.product?.modal_number}` },
        { name: 'Brand Color', selector: (row: any) => row.color },
        { name: 'Glass Size/Power', selector: (row: IBarcodeStatusData) => row.invoice_product_detail?.product?.glass_size },
        { name: 'SRP (₹)', selector: (row: IBarcodeStatusData) => `₹${numberWithCommas(row.invoice_product_detail?.product?.product_srp)}` },

    ]

    const columnsForContactLens = [
        {
            name: (
                <input
                    type={"checkbox"}
                    checked={pendingStatusList?.data.every((value: any) =>
                        selectedItems.includes(value.id)
                    )}
                    onChange={(e: any) => handleAllChecked(e.target.checked)}
                />
            ),
            selector: (row: IBarcodeStatusData) => (
                <input
                    type="checkbox"
                    checked={selectedItems.includes(row.id)}
                    onClick={() => handleSelectToggle(row)}
                />
            ),
            center: true,
        },
        {
            name: "Barcode",
            selector: (row: IBarcodeStatusData) => `${row.bar_code}`,
            sortable: true,
        },
        {
            name: "Date",
            selector: (row: any) =>
                row?.createdAt ? moment(row?.createdAt).format("DD/MM/YYYY") : "-",
        },
        {
            name: "Brand",
            selector: (row: IBarcodeStatusData) =>
                row.invoice_product_detail?.product?.brand?.brand_name,
        },
        {
            name: "Store Name",
            selector: (row: IBarcodeStatusData) => row?.store?.store_name || "",
        },
        {
            name: "Cover Photo",
            cell: (row: any) => (
                <CustomImages
                    src={
                        row.invoice_product_detail?.product?.images[0] &&
                        row.invoice_product_detail?.product?.images[0]
                    }
                />
            ),
            center: true,
        },
        {
            name: "Product Type",
            selector: (row: IBarcodeStatusData) =>
                row.invoice_product_detail?.product?.product_type?.name,
        },
        {
            name: "Product ID",
            selector: (row: IBarcodeStatusData) =>
                `${row.invoice_product_detail?.product?.product_id_display}`,
        },
        {
            name: "SRP (₹)",
            selector: (row: IBarcodeStatusData) =>
                `₹${numberWithCommas(
                    row.invoice_product_detail?.product?.product_srp
                )}`,
        },
        { name: "Status", selector: (row: IBarcodeStatusData) => row.isActive },
    ];

    const coloredDivs = (color: any, index: number) => {
        if (color?.invoice_product_detail?.product?.specx_colors?.length === 2) {
            // Handle the case where there are two colors
            const [color1, color2] = color?.invoice_product_detail?.product?.specx_colors;

            return (
                <div key={`color${index}`} className="diagonal-color-div cursor-pointer"
                    data-tooltip-id={`color${index}`}
                    data-tooltip-content={color?.brand_color_name}
                >
                    <div
                        className="diagonal-color-div cursor-pointer rounded-circle"
                        style={{ backgroundColor: color1?.color_code }}
                    ></div>

                    <Tooltip id={`color${index}`} />
                </div>
            );
        } else if (color?.invoice_product_detail?.product?.specx_colors?.length === 1) {
            // Handle the case where there is only one color
            const singleColor = color?.invoice_product_detail?.product?.specx_colors;
            return (
                <div key={`color${index}`}
                    style={{ backgroundColor: singleColor[0].color_code, }}
                    // style={{ backgroundColor: singleColor[0].color_code, width:"10px",height:"10px" }}
                    className="color-div cursor-pointer"
                    data-tooltip-id={`color${index}`}
                    data-tooltip-content={color.brand_color_name}
                >

                    <Tooltip id={`color${index}`} />
                </div>
            );
        } else {
            return null; // Handle other cases or add error handling as needed
        }
    };

    const selectedToggle = async () => {
        try {
            const payload = {
                barcode_status: constant?.BARCODE_STATUS?.APPROVED,
                inventory_products: selectedItems
            }
            await service.barcodeStatus.statusVerificationService(payload);
            getPendingStatus({})
            // setIsButtonActive(false);

        } catch (error) {
            console.log(error)
        }
    }
    const _handleDownloadBarcode = () => {
        const resultArray = allBarcode?.filter((obj: { id: string }) => selectedItems?.includes(obj.id))
            .map((obj: any) => ({
                brand_name: obj.invoice_product_detail.product.brand.brand_name,
                product_srp: obj.invoice_product_detail.product.product_srp,
                product_id_display: obj.invoice_product_detail.product.product_id_display,
                cover_img: obj.invoice_product_detail.product.images[0],
                bar_code: obj.bar_code,
            }));
        setDownloadBarcode(resultArray)
    }

    useEffect(() => {
        if (inputRef?.current) {
            inputRef.current.value = "";
        }
    }, [typeValue]);
    return (
        <div>
            <div className="d-flex mb-2">
                <ContactLensContainer
                    value={isContactLensProduct}
                    onStatusChange={(value: boolean) => setIsContactLensProduct(value)}
                />
            </div>
            <div className="row align-items-center">
                <div className="col-5 d-flex gap-3">
                    <div style={{ minWidth: "200px" }}>
                        <Search refs={inputRef} searchCallBack={(e: string) => { setSearch(e) }} className="w-100" />
                    </div>
                    <div className="" style={{ color: "#000000", minWidth: "230px" }}>
                        <Select
                            className={"w-100 select-min-height"}
                            options={storeData}
                            onChange={(e: any) => handleChange(e)}
                            placeholder={"Select Store"}
                            isMulti={true} />
                    </div>
                </div>
                {pendingStatusList && pendingStatusList?.data?.length > 0 &&
                    (
                        <div className='col-7 d-flex gap-4 justify-content-end'>
                            <div className='my-auto'>
                                <ToggleBarcodePrice togglePrice={togglePrice}
                                    handleRadioChange={(value) => setTogglePrice(value)} />
                            </div>

                            <div className=''>
                                <Button onClick={() => _handleDownloadBarcode()} isDisabled={selectedItems?.length === 0}>
                                    Download
                                </Button>
                            </div>
                            {(typeValue == constant.BRAND_CATEGORY[0].value) && <div className='' style={{ minWidth: "174px" }}>
                                <Button onClick={() => selectedToggle()}
                                    isDisabled={selectedItems?.length === 0}
                                >
                                    Approve Barcode
                                </Button>
                            </div>}
                        </div>
                    )}
            </div>

            <div>
                <Table
                    columns={!isContactLensProduct ? columns : columnsForContactLens}
                    // selectableRows
                    // contextActions={contextActions}
                    // handleSelectedRow={handleRowSelected}
                    data={pendingStatusList?.data?.map((item: any, index: number) => {
                        return ({
                            ...item,
                            color: <>
                                {coloredDivs(item, index)}</>,
                            isActive: item.isActive ? (
                                <div className="background-light-green border-product-radius">
                                    <p className="color-green m-1 p-1">{constant.STATUS.ACTIVE}</p>
                                </div>) : <div className="background-light-red border-product-radius">
                                <p className="color-red m-1 p-1">{constant.STATUS.DEACTIVE}</p>
                            </div>
                        })
                    })}
                />
            </div>

            {pendingStatusList?.data?.length > 0 && <div>
                <Pagination
                    totalCount={pendingStatusList?.count}
                    currentPage={currentPage}
                    totalPages={pendingStatusList?.totalPages}
                    onChangePage={(page: any) => setCurrentPage(page)}
                    setLimit={(e: number) => setLimitPerPage(e)}
                    limit={limit}
                />
            </div>}
            {/* <div className='' style={{ marginTop: '20%' }}></div> */}
            {
                <div className='m-4 mt-5 d-none'>
                    <Barcode downloadBarcode={downloadBarcode} togglePrice={togglePrice} />
                </div>
            }

        </div>
    )
}
export default pendingIndex