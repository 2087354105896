import React from 'react'
import atoms from '../../atoms';
import constant from '../../../constants/constant';
import moment from 'moment';
import { findConstantLabel } from '../../../constants/utils'
import BrandPlaceHolder from '../../../assets/Images/placeholderimg.png';

const { Image } = atoms
const Index = ({ barcodeData }: any) => {

    const style = {
        height: "100%",
        width: "100%",
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundImage: `url(${barcodeData?.product?.images[0] || BrandPlaceHolder})`
    }

    return (
        <>
            <div className='row d-flex'>
                <div className='col-6'>
                    <div
                        key="productImage"
                        style={style}
                        className='card img-fluid"'>
                    </div>
                </div>
                <div className='col-6'>
                    <div className='d-flex align-items-center justify-content-start gap-2'>
                        <h2 className='mb-0 fs-16 fw-700' style={{ minWidth: "100px" }}>Product ID :</h2>
                        <div className='d-flex'>
                            <div className='id-input-design d-flex justify-content-between align-items-center gap-2'>
                                <h2 className='mb-0 fs-16 fw-400 dark-gray'>{barcodeData?.product?.product_id_display ? barcodeData?.product?.product_id_display : ""}</h2>
                            </div>
                        </div>
                    </div>
                    <div className='d-flex align-items-center justify-content-start gap-2 mt-3'>
                        <h2 className='mb-0 fs-16 fw-700' style={{ minWidth: "100px" }}>Barcode :</h2>
                        <div className='d-flex'>
                            <div className='id-input-design d-flex justify-content-between align-items-center gap-2'>
                                <h2 className='mb-0 fs-16 fw-400 dark-gray'>{barcodeData?.bar_code ? barcodeData?.bar_code : ""}</h2>
                            </div>
                        </div>
                    </div>
                    <div className='d-flex align-items-center justify-content-start gap-2 mt-3'>
                        <h2 className='mb-0 fs-16 fw-700' style={{ minWidth: "100px" }}>Store Name :</h2>
                        <h2 className='mb-0 fs-18 fw-400'>{barcodeData?.store?.store_name}</h2>
                    </div>
                    <div className="mt-3">
                        <div className="d-flex flex-wrap align-items-center gap-2">
                            <span className='fs-20 fw-500'>{barcodeData?.product?.brand?.brand_name ? barcodeData?.product?.brand?.brand_name : ""}</span>
                        </div>
                    </div>
                    {barcodeData?.product?.description && <div className="mt-3">
                        <div className="d-flex flex-wrap align-items-center gap-3 ">
                            <span className='fs-18 fw-700'>{barcodeData?.product?.description}</span>
                        </div>
                    </div>}
                    <div className='row'>
                        <div className='col-6'>
                            <div className="mt-3">
                                <div className="d-flex flex-wrap align-items-center gap-2">
                                    <h2 className='mb-0 fs-18 fw-600'>Product SRP :</h2>
                                    <span className='fs-18 fw-400'>₹{barcodeData?.product?.product_srp ? barcodeData?.product?.product_srp : ""}</span>
                                </div>
                            </div>
                            {barcodeData?.product?.is_contact_lens && <div className="mt-3">
                                <div className="d-flex flex-wrap align-items-center gap-2">
                                    <h2 className='mb-0 fs-18 fw-600'>Period use :</h2>
                                    <span className='fs-18 fw-400'>{barcodeData?.product?.period_use ? barcodeData?.product?.period_use : ""}</span>
                                </div>
                            </div>}
                            {!barcodeData?.product?.is_contact_lens && <div className="mt-3">
                                <div className="d-flex flex-wrap align-items-center gap-3 ">
                                    <h2 className='mb-0 fs-18 fw-600'>Brand Color Code :</h2>
                                    <span className='fs-18 fw-400'>{barcodeData?.product?.brand_color_code}</span>
                                </div>
                            </div>}
                            {!barcodeData?.product?.is_contact_lens && <div className="mt-3">
                                <div className="d-flex flex-wrap align-items-center gap-2 ">
                                    <h2 className='mb-0 fs-18 fw-600'>Lense Color :</h2>
                                    {barcodeData?.product?.lens_colors && barcodeData?.product?.lens_colors?.map((item: any, index: number) => {
                                        return (
                                            <span className='fs-18 fw-400'>{item?.color_name},</span>
                                        )
                                    })}
                                </div>
                            </div>}
                            {!barcodeData?.product?.is_contact_lens && <div className="mt-3">
                                <div className="d-flex flex-wrap align-items-center gap-2">
                                    <h2 className='mb-0 fs-18 fw-600'>Glass Size/Power :</h2>
                                    <span className='fs-18 fw-400'>{barcodeData?.product?.glass_size ? barcodeData?.product?.glass_size : ""}MM</span>
                                </div>
                            </div>}
                            {!barcodeData?.product?.is_contact_lens && <div className="mt-3">
                                <div className="d-flex flex-wrap align-items-center gap-2">
                                    <h2 className='mb-0 fs-18 fw-600'>Product With Case :</h2>
                                    <span className='fs-18 fw-400'>{barcodeData?.product?.product_with_case ? "Yes" : "No"}</span>
                                </div>
                            </div>}
                        </div>
                        {barcodeData?.product?.is_contact_lens && <div className='col-6'>
                            <div className="mt-3">
                                <div className="d-flex flex-wrap align-items-center gap-2">
                                    <h2 className='mb-0 fs-18 fw-600'>Pack of :</h2>
                                    <span className='fs-18 fw-400'>{barcodeData?.product?.pack_of_quantity ? barcodeData?.product?.pack_of_quantity : ""}</span>
                                </div>
                            </div>
                            <div className="mt-3">
                                <div className="d-flex flex-wrap align-items-center gap-2">
                                    <h2 className='mb-0 fs-18 fw-600'>Color :</h2>
                                    <span className='fs-18 fw-400'>{barcodeData?.product?.color?.color_name ? barcodeData?.product?.color?.color_name : ""}</span>
                                </div>
                            </div>
                        </div>}
                        {!barcodeData?.product?.is_contact_lens && <div className='col-6'>

                            <div className="mt-3">
                                <div className="d-flex flex-wrap align-items-center gap-2 ">
                                    <h2 className='mb-0 fs-18 fw-600'>Bridge Size :</h2>
                                    <span className='fs-18 fw-400'>{barcodeData?.product?.bridge_size ? barcodeData?.product?.bridge_size : ""}MM</span>
                                </div>
                            </div>

                            <div className="mt-3">
                                <div className="d-flex flex-wrap align-items-center gap-2">
                                    <h2 className='mb-0 fs-18 fw-600'>Warrantee :</h2>
                                    <span className='fs-18 fw-400'>{barcodeData?.product?.warranty ? findConstantLabel(constant?.PRODUCT_WARRANTY, barcodeData?.product?.warranty) : "-"}</span>
                                </div>
                            </div>

                            <div className="mt-3">
                                <div className="d-flex flex-wrap align-items-center gap-2">
                                    <h2 className='mb-0 fs-18 fw-600'>Product Color :</h2>
                                    {barcodeData?.product?.specx_colors && barcodeData?.product?.specx_colors?.map((item: any, index: number) => {
                                        return (
                                            <span className='fs-18 fw-400'>{item?.color_name},</span>
                                        )
                                    })}
                                </div>
                            </div>

                            <div className="mt-3">
                                <div className="d-flex flex-wrap align-items-center gap-2">
                                    <h2 className='mb-0 fs-18 fw-600'>Temple Size :</h2>
                                    <span className='fs-18 fw-400'>{barcodeData?.product?.temple_size ? barcodeData?.product?.temple_size : ""}MM</span>
                                </div>
                            </div>
                        </div>}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Index