import atoms from '../../../atoms'
import PlaceHolderImage from '../../../../assets/Images/placeholderimg.png'
import { findConstantLabel, numberWithCommas } from '../../../../constants/utils';
import moment from 'moment';
import constant from '../../../../constants/constant';

    const { Search, Table, Pagination,CustomImages } = atoms
const Index = ({ setSearch, deliveredListing, setLimitPerPage, setCurrentPage, currentPage, limit, }: any) => {
    const columns = [
        { name: 'Product ID', selector: (row: any) => row?.product?.product_id_display,minWidth: "150px" },
        { name: 'Movement ID', selector: (row: any) => row?.procurement?.procurement_display_id ,minWidth: "60px" },
        { name: 'Order ID/ Warranty Claim ID', selector: (row: any) => row?.warranty_claimed_product?.claim_id ?  row?.warranty_claimed_product?.claim_id : row?.order?.order_id_display, minWidth: "110px"},
        {
            name: 'Product Type / Frame /Glass Size/Power', selector: (row: any) =>
                `${row?.product?.product_type?.name}
         ${findConstantLabel(constant?.PRODUCT_RIM_TYPE, row?.product?.rim_type)}
         ${row?.product?.glass_size}`,minWidth: "150px"
        },
        { name: 'SRP', selector: (row: any) =>`₹${numberWithCommas(parseFloat(Number(row?.product?.product_srp)?.toFixed(2)))}` || '-', },
        { name: 'Landing Cost', selector: (row: any) =>`₹${numberWithCommas(parseFloat(Number(row?.landing_cost)?.toFixed(2)))}` || '-', },
        { name: 'Date Time', selector: (row:any) => `${row?.createdAt ? moment(row?.updatedAt).format('DD/MM/YYYY , H:mm:ss') : "--"}`, sortable: true ,minWidth: "150px"},
        { name: 'Cover Photo', cell: (row: any) => <CustomImages src={row?.product?.images?.length>0 && row?.product?.images[0]} />, center: true,minWidth: "150px" },
        { name: 'Requested Store Name', selector: (row: any) => row?.requested_store?.store_location || row?.requested_store?.store_name,minWidth: "250px"},
        // { name: 'Pickup Store', selector: (row: any) => row?.pickup_store?.store_name, },
        // { name: 'Delivery Boy', selector: (row: any) => row., },
        // { name: 'Order ID', selector: (row: any) => row?.order?.order_id_display, },
        { name: 'Status', selector: (row: any) => row.status,minWidth: "150px" },

    ]
    return (
        <div>
            <div className="row align-items-center">
                <div className="col-6">
                    <Search searchCallBack={(e: string) => { setSearch(e) }} />
                </div>
            </div>
            <div>
                <Table
                    AdditionalClassName="add-invertory-table data-table-container"
                    columns={columns}
                    data={deliveredListing?.data?.map((item: any) => {
                        return ({
                            ...item,
                            status: item.status === constant._STATUS.DELIVERED ? (
                                <div className="background-light-green border-product-radius"><p className="color-green m-1 p-1">{'Delivered'}</p></div>) : item.status === constant._STATUS.DELIVER ? (<div className="background-light-red border-product-radius"><p className="color-red m-1 p-1">{"To be Delivered"}</p></div>) : '',
                        })
                    })}
                />
            </div>
            <div>
                <Pagination
                    totalCount={deliveredListing?.count}
                    currentPage={currentPage}
                    totalPages={deliveredListing?.totalPages}
                    onChangePage={(page: any) => setCurrentPage(page)}
                    setLimit={(e: number) => setLimitPerPage(e)}
                    limit={limit}
                />
            </div>
        </div>
    )
}

export default Index