import React, { useContext, useEffect, useState } from 'react'
import Wrapper from '../../../../components/common/Wrapper/Wrapper'
import organisms from '../../../../components/organisms'
import service from '../../../../api/services'
import { HeaderHeading } from '../../../../Context'
import { BrandDataProps } from '../../../../components/organisms/Data/Brand/interface'
import { useLocation } from 'react-router'


const { Brand } = organisms
const Index = () => {
    const location = useLocation();
    const [search, setSearch] = useState<any>('');
    const [brandData, setBrandData] = useState<BrandDataProps[]>([])
    const { setHeader,setSidebar }: any = useContext(HeaderHeading);
    const urlParams = new URLSearchParams(location.search);
    const typeValue = urlParams?.get('brand-type')
    const getBrandList = async () => {
        try {
            let response = await service.productData.brandListingService({ "search": search,brand_category:typeValue })
            if (response.status === 200) {
                setBrandData(response.data.data)
            }
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(()=>{
        setBrandData([])
        setSearch('')
    },[typeValue])
    useEffect(() => {
        setHeader("Content - Brands")
       
        setSidebar(false)
        getBrandList()
    }, [search,typeValue])


    return (
        <Wrapper>
            <Brand
                setSearch={setSearch}
                brandData={brandData}
                setBrandData={setBrandData}
                search={search}
            />
        </Wrapper>
    )
}
export default Index