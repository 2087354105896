import ConfirmationModal from './ConfirmationModal'
import CreateModal from './CreateModal'
import SidePopUp from './Sidepopup'
import SearchTab from './SearchTab'
import CheckboxDropdown from './CheckboxDropdown'
import LightBox from './LightBox'
import BrandTable from './BrandTable'
import QuantityTable from './Inventory/quantityTable'
import Barcode from './Barcode'
import PriceRange from './PriceRange'
import BulkSidePopUp from './BulkUpload/bulkUploadLightBox'
import ImagePopup from './ImagePopup'
import TopBar from './TopBar'
import SubAccountModal from './SubAccountPopup'
import BillingSummary from './BillingSummary'
import PurchaseCard from './PurchaseCard'
import CreateSideModal from './CreateSideModal'
import InventoryAudit from './InventoryAudit'
import Report from './Report'
import LostBarcodeDetail from "./LostBarcodeDetail"
import ToggleBarcodePrice from "./BarcodeToggleButton"
import ContactLensContainer from  "./ContactLensContainer"
import TableItemStatus from './TableStatus'

const molecules = {
    ConfirmationModal,
    CreateModal,
    SidePopUp,
    SearchTab,
    CheckboxDropdown,
    LightBox,
    BrandTable,
    QuantityTable,
    Barcode,
    PriceRange,
    BulkSidePopUp,
    ImagePopup,
    TopBar,
    SubAccountModal,
    BillingSummary,
    PurchaseCard,
    CreateSideModal,
    InventoryAudit,
    Report,
    LostBarcodeDetail,
    ToggleBarcodePrice,
    ContactLensContainer,
    TableItemStatus
}
export default molecules